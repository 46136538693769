import AircraftColumnText from './AircraftColumnText';
import OptimizedColumnText from './OptimizedColumnText';
const generateScales = (hourStep: number) => [
  { unit: 'day', step: 1, format: '%d %M', css: () => 'font-xl text-medium' },
  { unit: 'day', step: 1, format: '%l', css: () => 'text-medium' },
  { unit: 'hour', step: hourStep, format: '%H' }
];

export const zoomLevels = [
  { name: 'One', hourStep: 1 },
  { name: 'Two', hourStep: 2 },
  { name: 'Three', hourStep: 3 },
  { name: 'Four', hourStep: 4 },
  { name: 'Six', hourStep: 6 }
];

const hourlyLevels = zoomLevels.map(level => ({
  name: level.name,
  scale_height: 66,
  min_column_width: 40,
  scales: generateScales(level.hourStep)
}))

const halfHourLevel = {
  name: '30 minutes',
  scale_height: 66,
  min_column_width: 40,
  scales: [
    { unit: 'day', step: 1, format: '%d %M, %l', css: () => 'font-xl text-medium' },
    { unit: 'hour', step: 1, format: '%H', css: () => 'text-medium' },
    { unit: 'minute', step: 30, format: '%i' }
  ]
}

export const zoomConfig = {
  levels: [halfHourLevel, ...hourlyLevels]
};

export const columns = [
  {
    name: 'text',
    label: '  ',
    template: AircraftColumnText,
    width: 100
  },
  {
    name: 'text',
    label: ' ',
    template: OptimizedColumnText,
    width: 40
  }
]
