import React from 'react';
import { useQuery } from 'react-query';
import { getJetInsightCalendar } from '../../api/axios';
import GanttView from './GanttView';
// import Scheduler from './Scheduler';
// import { ICalEvent } from './types';

const CalendarView: React.FC = () => {
  const { data: calendars, isLoading, error } = useQuery('jetInsightCalendar', getJetInsightCalendar);
  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>An error occurred: {(error as Error).message}</div>;
  if (!calendars) return <div>No aircraft calendars found</div>;

//   const logDataUpdate = (action: string, ev: ICalEvent, id: string) => {
//     const text = ev && ev.summary ? ` (${ev.summary})` : '';
//     const message = `event ${action}: ${id} ${text}`;
//     console.log(message);
//   };

  return (
    <div>
      {/* <Scheduler events={events} timeFormatState={true} onDataUpdated={logDataUpdate} /> */}
      <GanttView calendars={calendars} />
    </div>
  );
};

export default CalendarView;
