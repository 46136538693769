const presets = [
  {
    name: "Immediate",
    description: "in 3 hours for the rest of the day",
    date: () => {
      const today = new Date();
      today.setHours(today.getHours() + 3, 0, 0, 0); // Set to 3 hours from now
      return today;
    },
    duration: 1
  },
  {
    name: "Tomorrow + 2",
    description: "Plan from tomorrow for 2 days",
    date: () => {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(9, 0, 0, 0); // Set to 9:00 AM
      return tomorrow;
    },
    duration: 2
  },
  {
    name: "2 + 3",
    description: "Plan from the day after for 3 days",
    date: () => {
      const dayAfter = new Date();
      dayAfter.setDate(dayAfter.getDate() + 2);
      dayAfter.setHours(9, 0, 0, 0); // Set to 9:00 AM
      return dayAfter;
    },
    duration: 3
  }
];

export default presets;
