import { FleetType } from "api/constants";
import type { Leg } from "api/types";
import clsx from "clsx";
import type { Task } from "dhtmlx-gantt";
import ReactDOMServer from "react-dom/server";

const TaskBarText = (task: Task, legs: Leg[]) => {
  const { id } = task;
  const leg = legs.find((leg) => leg.id === (id as string).split("_")[0]);
  if (!leg) return "";
  
  const isPosition = !leg.demandId
  const textStyle = clsx(leg.fleetType === FleetType.offFleet && "text-white");

  const component = (
    <div className={clsx(isPosition ? "text-end" : "flex justify-between", "px-1 font-medium")}>
        {(task.text as string).split(" - ").map((text, index) => (
            <div key={index} className={textStyle }>{text}</div>
        ))}
    </div>
  ) 

  return ReactDOMServer.renderToString(component);
}; 


export default TaskBarText;