import { Transition } from '@headlessui/react';
import { CloseIcon } from 'assets/Icons';
import clsx from 'clsx';
import { Fragment, useEffect } from 'react';
import { Notification, NotificationType } from './types';

interface Props {
  notification: Notification;
  onDismiss: (id: string) => void;
  dismissible?: boolean;
}

const NotificationToast = ({ notification, onDismiss, dismissible = true }: Props) => {
  useEffect(() => {
    if (notification.duration) {
      const timer = setTimeout(() => {
        onDismiss(notification.id);
      }, notification.duration);
      return () => clearTimeout(timer);
    }
  }, [notification, onDismiss]);

  const getTypeColor = (prefix: string) => {
    switch (notification.type) {
      case NotificationType.SUCCESS:
        return `${prefix}-savingsGreen`;
      case NotificationType.ERROR:
        return `${prefix}-cajaRed`;
      case NotificationType.INFO:
        return `${prefix}-veniceBlue`;
      default:
        return `${prefix}-helles`;
    }
  };

  const actionClass = clsx('text-sm font-medium underline hover:opacity-80');

  return (
    <Transition
      appear
      show={true}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className={clsx(
          'pointer-events-auto w-full max-w-sm rounded-md border-2 bg-white text-veniceBlue shadow-lg',
          getTypeColor('border')
        )}
      >
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-1">
              <p className={clsx("text-xl font-bold", getTypeColor('text'))}>{notification.title}</p>
              <p className="mt-1">{notification.message}</p>
              <div className="mt-3 flex justify-end">
                {notification.action ? (
                  <button
                    onClick={() => {
                      notification.action?.onClick()
                      onDismiss(notification.id)
                    }}
                    className={actionClass}
                  >
                    {notification.action.label}
                  </button>
                ) : (
                  <button
                    onClick={() => onDismiss(notification.id)}
                    className={actionClass}
                  >
                    Dismiss
                  </button>
                )}
              </div>
            </div>
            <div className="ml-4 flex shrink-0">
              {dismissible && (
                <button onClick={() => onDismiss(notification.id)}>
                  <span className="sr-only">Close</span>
                  <CloseIcon/>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default NotificationToast;
