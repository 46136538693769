import type { Group, Leg } from 'api/types';
import CostSavingsReceipt from '../CostSavingsReceipt/CostSavingsReceipt';
import CrewToggle from './CrewToggle';
import DemandAircraftSelection from './DemandAircraftSelection';
import GroupSelection from './GroupSelection';
import { findGroupLabel, useGroupSelection, useSelectedGroup } from './SelectedContext';

type Props = {
  groups: Group[];
  legs: Leg[];
};

const AircraftViewNavigation = ({ groups, legs }: Props) => {
  const { selectedGroupId, setSelectedGroupId } = useSelectedGroup();
  const { selectedGroup } = useGroupSelection(groups, selectedGroupId);
  const { legIds } = selectedGroup;

  const displayLegs = legIds.map((legId) => legs.find((leg) => leg.id === legId)) as Leg[];

  const selectedIndex = findGroupLabel(selectedGroupId, groups)

  return (
    <>
      <section className="flex max-h-[58vh] grow flex-col gap-2 overflow-y-scroll rounded-b-lg bg-culturedBlush p-4">
        <GroupSelection groups={groups} selectedId={selectedGroupId} setSelectedId={setSelectedGroupId}/>
        <CrewToggle />
        <DemandAircraftSelection legs={displayLegs} groupIndex={selectedIndex}  />
      </section>
      <CostSavingsReceipt group={selectedGroup as Group} />
    </>
  );
};

export default AircraftViewNavigation;
