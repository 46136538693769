import { flatten } from 'lodash';
import { LegStatus } from '../constants';
import { Crew, Leg } from '../types';
import { createCrewForLegs } from './CrewFixture';
import { LegPairsSet } from './LegFixture';

type FixtureData = {
  legs: Leg[];
  crews: Crew[];
};

export const BundleFixtures = (): FixtureData => {
  const data: FixtureData = {
    legs: [],
    crews: []
  };

  [LegStatus.unchanged, LegStatus.optimized, LegStatus.unscheduled].forEach(
    (state) => {
      const legs = LegPairsSet(state);
      const crews = createCrewForLegs(legs, state);
      data.legs.push(...legs);
      data.crews.push(...flatten(crews));
    }
  );

  return data;
};

