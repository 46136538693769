import { CrewStatus } from 'api/constants';
import { countCrewStatuses, findCrewsForAircraft } from 'api/helpers';
import type { BaseGroup, Crew, Leg } from 'api/types';
import clsx from 'clsx';
import { mapCrewPOSToLabel } from 'components/GanttChartView/helpers';
import { countLabel, removeDuplicates } from 'helpers/utils';
import LabelText from './LabelText';
import { useSelectedGroup } from './SelectedContext';

type Props = {
  groups: BaseGroup[];
  crews: Crew[];
  legs: Leg[];
};

const CrewViewNavigation = ({ groups, crews, legs }: Props) => {
  const { selectedCrewGroupId, setSelectedCrewGroupId } = useSelectedGroup();
  const activeGroup = groups.find((group) => group.id === selectedCrewGroupId) || groups[0];
  const activeCrews = activeGroup.crewIds.map((crewId) => crews.find((crew) => crew.id === crewId)) as Crew[];  
  const activeLegs = activeGroup.legIds.map((legId) => legs.find((leg) => leg.id === legId)) as Leg[]; 
  const tailNumbers = removeDuplicates(activeLegs.map((leg) => leg.tailNumber));

  return (
    <div className='rounded-b-lg bg-culturedBlush p-4'>
      
      <div className="grid grid-cols-3 px-2 uppercase">
        <LabelText text="Group" variant="small" />
        <LabelText text="Changes" variant="small" className='col-span-2' />
      </div>
      <ul role="listbox" aria-activedescendant={selectedCrewGroupId} tabIndex={0}>
        {groups.map((group, index) => (
          <CrewGroupOption
            key={group.id}
            index={index === groups.length - 1 ? "All": String(index + 1).padStart(2, '0') }
            id={group.id}
            crews={group.crewIds.map((crewId) => crews.find((crew) => crew.id === crewId)) as Crew[]}  
            selected={selectedCrewGroupId === group.id}
            onSelect={setSelectedCrewGroupId}
          />
        ))}
      </ul>
      <LabelText text="Crew by Aircraft" className='mb-2 mt-4'/>
      <ul className="flex max-h-[58vh] grow flex-col overflow-y-scroll">
        {tailNumbers.map((tailNumber) => (
          <li key={tailNumber} className="group hover:bg-cerebralGrey/30">
            <div className="pl-2 group-hover:bg-cerebralGrey/50">{tailNumber}</div>
            <ul className="pl-4">
              {findCrewsForAircraft(activeCrews, tailNumber).map((crew) => (
                  <li key={crew.id}>
                    <span className='text-sm'>{crew.first} {crew.last}</span>{' '}
                    <span className="text-xs text-veniceBlue">
                      {mapCrewPOSToLabel(crew.pos)} {crew.status !== CrewStatus.unchanged && crew.status}
                    </span>
                  </li>
                ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CrewViewNavigation;

type OptionProps = {
  index: string;
  id: string;
  crews: Crew[];
  selected: boolean;
  onSelect: (id: string) => void;
};

const CrewGroupOption = ({ index, id, crews, selected, onSelect }: OptionProps) => {

  const optionClassname = (selected: boolean) =>
    clsx('flex cursor-pointer hover:bg-cerebralGrey/50', selected && 'bg-cerebralGrey/50 font-bold');

  const { numberReassigned, numberNew, numberDeleted } = countCrewStatuses(crews);
  const changes = [
    countLabel(numberReassigned, 'reassigned'),
    countLabel(numberNew, 'new'),
    countLabel(numberDeleted, 'deleted')
  ]
    .filter((l) => l)
    .join(', ');
  return (
    <li
      id={id}
      role="option"
      aria-selected={selected}
      onClick={() => onSelect(id)}
      className={optionClassname(selected)}
      tabIndex={-1}
    >
      <span className="mr-4">{index}</span>
      <span>{changes}</span>
    </li>
  );
};
