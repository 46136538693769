import type { Crew, Leg } from 'api/types';
import { ViewMode } from 'components/GroupNavigation/ViewTabs';
import { gantt } from 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/skins/dhtmlxgantt_material.css';
import { useEffect, useRef } from 'react';
import { CrewTooltipText, TooltipText } from '../TooltipText';
import { columns, zoomConfig, zoomLevels } from './configurations';
import CrewBarText from './CrewBarText';
import {
  extractLinksFromCrews,
  extractLinksFromLegs,
  extractParentTasksFromAircaft,
  getAdjacentTaskText,
  getLinksBasedOnViewMode,
  mapCrewToTasks,
  mapLegToTask,
  sortFlightTasks,
  validateTasks
} from './helpers';
import './styles.css';
import TaskBarText from './TaskBarText';
import ZoomControl from './ZoomControl';

type Props = {
  selectedGroupId: string;
  selectedCrewGroupId: string;
  selectedCombineGroupId: string;
  legs: Leg[];
  crews: Crew[];
  viewMode: ViewMode;
  duration: number;
};

const GanttChart = ({
  selectedGroupId,
  selectedCrewGroupId,
  selectedCombineGroupId,
  legs,
  crews,
  viewMode,
  duration, 
}: Props): JSX.Element => {
  const ganttRef = useRef(null);
  
  useEffect(() => {
    gantt.config.scale_height = 66;
    gantt.config.scale_offset_minimal = false;
    gantt.config.duration_unit = 'minute';
    gantt.config.min_column_width = 40;
    gantt.config.columns = columns;
    gantt.plugins({
      tooltip: true
    });
    gantt.config.readonly = true;

    gantt.ext.zoom.init(zoomConfig);
    const defaultLevel = zoomLevels.find(level => level.hourStep === duration)?.name;
    gantt.ext.zoom.setLevel(defaultLevel || 'One');
    gantt.init(ganttRef.current as unknown as HTMLElement);
  });

  useEffect(() => {
    const parentTasks = extractParentTasksFromAircaft(legs);
    const flightTasks = sortFlightTasks(legs.map(mapLegToTask));

    const crewTasks = crews.map(mapCrewToTasks);

    const tasksToRender = [...parentTasks, ...flightTasks, ...crewTasks];
    const legLinks = extractLinksFromLegs(legs);
    const crewLinks = extractLinksFromCrews(crews);
    const linksToRender = getLinksBasedOnViewMode(viewMode, legLinks, crewLinks);

    validateTasks(tasksToRender);

    gantt.clearAll();
    gantt.parse({
      data: tasksToRender,
      links: linksToRender
    });
  }, [legs, crews, selectedGroupId, selectedCrewGroupId, selectedCombineGroupId, viewMode]);

  
  gantt.templates.tooltip_text = (start, end, task) => {
    if (task.isCrew) return CrewTooltipText(task, crews);
    return TooltipText(task, legs);
  };

  gantt.templates.task_class = (start, end, task) => {
    if (task.isCrew) return 'crew-task';
    return task.shape;
  };

  gantt.templates.grid_row_class = (start, end, task) => task.rowClass;
  gantt.templates.task_row_class = (start, end, task) => task.rowClass;

  const showInside = duration === 1;

  gantt.templates.leftside_text = (start, end, task) => {
    if (task.isCrew)return '';
    const peerTasks = gantt.getTaskBy('parent', task.parent);
    return getAdjacentTaskText(peerTasks, task, start.getTime(), true, duration);
  };

  gantt.templates.rightside_text = (start, end, task) => {
    if (task.isCrew) return '';
    const peerTasks = gantt.getTaskBy('parent', task.parent);
    return getAdjacentTaskText(peerTasks, task, end.getTime(), false, duration);
  };
  
  gantt.templates.task_text = (start, end, task) => {
    if (task.isCrew) return CrewBarText(task, crews);
    if (showInside) return TaskBarText(task, legs);
    return '';
  };

  return (
    <section className="col-span-4 mb-4 rounded-lg bg-culturedBlush p-4">
      <ZoomControl gantt={gantt} />
      <div className="h-[calc(100vh-110px)] w-full ">
        <div id="gantt" ref={ganttRef}/>
      </div>
    </section>
  );
};

export default GanttChart;

