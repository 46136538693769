import { createOptimization, getRecentEntries, PostOptimizationPayload } from 'api/axios';
import useErrorHandler from 'components/NotFound/useErrorHandler';

import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import EntryList from './EntryList';
import PlanningWindowForm from './PlanningWindowForm';

const Launcher = (): JSX.Element => {
  const navigate = useNavigate();
  const {
    error: recentEntriesError,
    handleError: handleRecentEntriesError,
    ErrorComponent: RecentEntriesErrorComponent
  } = useErrorHandler();

  const { mutate: postForm, isLoading } = useMutation(
    (request: PostOptimizationPayload) => createOptimization(request),
    {
      retry: false,
      onSuccess(data) {
        navigate('/compare-schedule', { state: { responseData: data } });
      },
      onError(error) {
        console.error('Error:', error);
      }
    }
  );

  const { data: recentEntries, isLoading: isLoadingRecentEntries } = useQuery({
    queryKey: 'Recent Entries',
    queryFn: () => getRecentEntries(10),
    onError: handleRecentEntriesError
  });

  return (
    <div className="grid grid-cols-3 gap-4 bg-helles">
      {isLoadingRecentEntries ? (
        <div>Loading historical runs...</div>
      ) : recentEntriesError ? (
        <RecentEntriesErrorComponent context="Failed to load recent entries" />
      ) : (
        <EntryList entries={recentEntries} />
      )}

      <div className="col-span-2 flex">
        <PlanningWindowForm isLoading={isLoading} onSubmit={(request) => postForm(request)} />
      </div>
    </div>
  );
};

export default Launcher;
