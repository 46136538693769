import clsx from "clsx";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

type Props = {
  selectedDate: Date | null;
  onChange: (date: Date | null) => void;
};

const DateTimePicker = ({ selectedDate, onChange }: Props) => {
  const defaultDatetime = selectedDate;
  const [date, setDate] = useState(defaultDatetime);
  const [time, setTime] = useState(defaultDatetime);

  useEffect(() => {
    if (!date || !time) return;

    const combinedDateTime = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      time.getHours(),
      time.getMinutes(),
    );
    onChange(combinedDateTime);
  }, [date, time, onChange]);

  useEffect(() => {
    if (selectedDate) {
      if (!date || date.getTime() !== selectedDate.getTime()) {
        setDate(selectedDate);
      }
      if (!time || time.getTime() !== selectedDate.getTime()) {
        setTime(selectedDate);
      }
    }
  }, [selectedDate]);

  const handleDateChange = (selectedDate: Date | null) => {
    if (!selectedDate) return;
    setDate(selectedDate);
    if (!time) onChange(selectedDate);
  };

  const handleTimeChange = (selectedTime: Date | null) => {
    if (!selectedTime) return;
    setTime(selectedTime);
    if (!date) onChange(selectedTime);
  };

  const inputClassName = clsx(
    "h-10 rounded-md border px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-offset-2",
  );

  return (
    <div>
      <label
        htmlFor="date-picker"
        className="block font-medium uppercase text-veniceBlue"
      >
        Start date
      </label>
      <div className="flex gap-2">
        <DatePicker
          name="date-picker"
          selected={date}
          enableTabLoop={false}
          placeholderText="Select Date"
          onChange={(value) => handleDateChange(value)}
          dateFormat="MMMM d, yyyy"
          className={inputClassName}
        />
        <DatePicker
          name="time-picker"
          selected={time}
          placeholderText="Select Time"
          enableTabLoop={false}
          showTimeSelect
          showTimeSelectOnly
          onChange={(value) => handleTimeChange(value)}
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="h:mm aa"
          className={inputClassName}
        />
      </div>
    </div>
  );
};

export default DateTimePicker;
