import type { CrewStatus, CrewType, FleetType, ScenarioType } from "./constants";

export type Leg = {
  id: string
  groupId?: string
  tcGroupId?: string
  cost: number
  international: boolean
  departAirport: string
  arriveAirport: string
  aircraftType: string
  tailNumber: string
  departTimeGMT: string
  arriveTimeGMT: string
  changedFlag: boolean
  scenario: ScenarioType
  fleetType: FleetType
  demandId?: string
  linkId?: string
  crewsId?: string[]
}

export type Crew = {
  id: string
  crewId: string
  crewGroupId?: string
  tcGroupId?: string
  tailNumber: string
  startTimeGMT: string
  endTimeGMT: string
  pos: CrewType
  last: string
  first: string
  scenario: ScenarioType
  status: CrewStatus
  linkId?: string
}

type Cost = {
  existing: number;
  optimized: number;
}

export type Group = BaseGroup & {
  changes: number;
  costs: Cost;
  offFleetCosts: Cost;
};

export type BaseGroup = {
  id: string;
  crewIds: string[];
  legIds: string[];
};

export type OptimizedResult = {
  tailNumbers: number;
  scenarioId: string;
  dateStart: Date;
  dateEnd: Date;
  legGroups: Group[];
  crewGroups: BaseGroup[];
  combinedGroups: Group[];
  crews: Crew[];
  legs: Leg[];
};

export enum RunStatus {
  success = 'success',
  error = 'error',
}

export type Entry = {
  id: string
  username: string
  runStart: string
  runEnd?: string
  exitStatus: RunStatus
  exitMessage?: string
  planningWindowStart: string
  planningWindowEnd: string
}
