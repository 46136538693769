import { useNotification } from './NotificationContext';
import NotificationToast from './NotificationToast';

export const NotificationCenter = () => {
    const { notifications, dismissNotification } = useNotification();

  return (
    <div className="pointer-events-none fixed bottom-0 right-0 z-50 max-h-screen space-y-4 overflow-hidden p-4">
      {notifications.map(notification => (
        <div key={notification.id} className="pointer-events-auto">
          <NotificationToast
            notification={notification}
            onDismiss={dismissNotification}
          />
        </div>
      ))}
    </div>
  );
};

export default NotificationCenter;

