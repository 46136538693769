import { getEntryById, getRecentEntries } from 'api/axios';
import type { OptimizedResult } from 'api/types';
import { useSelectedGroup } from 'components/GroupNavigation';
import useErrorHandler from 'components/NotFound/useErrorHandler';
import ScenarioSelector from 'components/ScenarioSelector';
import { useCallback, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useSearchParams } from 'react-router-dom';

import ChartContainer from './ChartContainer';

const DataWrapper = (): JSX.Element => {
  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();
  const idParams = searchParams.get('id');
  const { responseData }: { responseData: OptimizedResult | undefined } = location.state || {};

  const { selectedScenarioId, setSelectedScenarioId, setSelectedGroupId, setSelectedCrewGroupId, setSelectedCombineGroupId } =
    useSelectedGroup();

  const { error: recentEntriesError, handleError: handleRecentEntriesError, ErrorComponent: RecentEntriesErrorComponent } = useErrorHandler();
  const { error: entryError, handleError: handleEntryError, ErrorComponent: EntryErrorComponent } = useErrorHandler();

  const {
    data: recentEntries,
    isLoading: isLoadingRecentEntries,
  } = useQuery({
    queryKey: 'Recent Entries',
    queryFn: () => getRecentEntries(33),
    onError: handleRecentEntriesError
  });

  const {
    data: entry,
    isLoading: isLoadingEntry,
  } = useQuery({
    queryKey: ['Entry', selectedScenarioId],
    queryFn: () => getEntryById(selectedScenarioId as string),
    enabled: !!selectedScenarioId,
    onError: handleEntryError
  });

  const result = entry || responseData;

  useEffect(() => {
    if (idParams) return setSelectedScenarioId(idParams);
    // Select the first scenario if no data was passed from the Launcher
    if (!responseData && recentEntries?.length){
      setSelectedScenarioId(recentEntries[0].id);
      return
    }
    // Set scenarioId from the responseData if it exists
    if(responseData){
      setSelectedScenarioId(responseData.scenarioId);
      return
    }
  }, [responseData, recentEntries, idParams, setSelectedScenarioId]);

  const handleSelectScenario = useCallback(
    (id: string) => {
      setSelectedScenarioId(id);
      setSelectedGroupId('');
      setSelectedCrewGroupId('');
      setSelectedCombineGroupId('');
      setSearchParams({ id });
    },
    [setSelectedScenarioId, setSelectedGroupId, setSelectedCrewGroupId, setSelectedCombineGroupId, setSearchParams]
  );

  useEffect(() => {
    if (result) {
      const { legGroups, crewGroups, combinedGroups } = result;
      setSelectedGroupId(legGroups.find((group) => group.changes > 0)?.id || legGroups[0]?.id);
      setSelectedCrewGroupId(crewGroups[0]?.id);
      setSelectedCombineGroupId(combinedGroups.find((group) => group.changes > 0)?.id || combinedGroups[0]?.id);
    }
  }, [result, setSelectedGroupId, setSelectedCrewGroupId, setSelectedCombineGroupId]);

  const renderContent = () => {
    if (isLoadingRecentEntries) return <div>Loading recent entries...</div>;
    if (recentEntriesError) return <RecentEntriesErrorComponent context="Failed to load recent entries" />;
    
    return (
      <>
        <ScenarioSelector
          entries={recentEntries || []}
          selectedId={selectedScenarioId}
          onSelectId={handleSelectScenario}
        />
        {renderScenarioContent()}
      </>
    );
  };

  const renderScenarioContent = () => {
    if (isLoadingEntry) return <div>Loading selected scenario {selectedScenarioId}...</div>;
    if (entryError) return <EntryErrorComponent context={`Failed to load scenario ${selectedScenarioId}`} />;
    if (!result) return null;

    return <ChartContainer data={result as OptimizedResult} />;
  };

  return (
    <div className='bg-helles'>
      {renderContent()}
    </div>
  );
};

export default DataWrapper;
