import { useNavigate, useRouteError } from "react-router-dom";

type ErrorResponse = {
  data: unknown;
  status: number;
  statusText: string;
  message?: string;
  error: Error;
};

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();
  const error = useRouteError() as ErrorResponse;

  return (
    <div>
      <h1>404 - Not Found</h1>
      <p>The page you are looking for does not exist.</p>
      <div>{error.statusText || error.message}</div>
      <button onClick={() => navigate("/")}>Go Home</button>
    </div>
  );
};

export default NotFoundPage;
