import axios from "axios";
import { QueryClient } from "react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: (failureCount: number, error) => {
        let status;
        if (axios.isAxiosError(error)) {
          status = error.response?.status;
        }
        return status
          ? (status === 409 || status >= 500) && failureCount < 3
          : false;
      },
    },
  },
});
