import { CrewType, FleetType, ScenarioType } from 'api/constants';
import { TaskBarShape } from './types';

export const PILOT_ROW_LABEL = {
    [CrewType.pilot]: 'PIC',
    [CrewType.coPilot]: 'SIC'
  };
  
  export const PILOT_LONG_LABEL = {
    [CrewType.pilot]: 'PIC Pilot',
    [CrewType.coPilot]: 'SIC Co pilot'
  };
  
  export const pilotRowLabels = Object.values(PILOT_ROW_LABEL);
  
  export const taksBarColor = {
    unchange: '#B6C4CF',
    changed: '#FFB677', // orange
    optimized: '#CDE990', // lime green
    maintenance: '#F9ED69', // neon yellow
    unschedule: '#E6ECF0', // light grey
    offFleet: '#B5EAEA' // sky blue
  };
  
  export const legTaskHeight = {
    row_height: 50,
    bar_height: 25
  };

  export const taskHeightWithCrew = {
    row_height: 30,
    bar_height: 15
  };
  
  export const crewTaskHeight = {
    row_height: 20,
    bar_height: 15
  };
  
  export const getStatusColorWithOpacity = (
    scenario: ScenarioType,
    fleetType: FleetType,
    changedFlag: boolean,
    demandId?: string
  ) => {
    let barColor = 'black';
  
    if (!changedFlag) {
      barColor = taksBarColor.unchange;
    } else if (fleetType === FleetType.unscheduled) {
      barColor = taksBarColor.unschedule;
    } else if (fleetType === FleetType.offFleet) {
      barColor = taksBarColor.offFleet;
    } else if (scenario === ScenarioType.existing) {
      barColor = taksBarColor.changed;
    } else if (scenario === ScenarioType.optimized) {
      barColor = taksBarColor.optimized;
    } else {
      barColor = demandId ? barColor : `${barColor}99`;
    }
    return barColor;
  };
  
  export const getTaskBarShape = (fleetType: FleetType, demaindId?: string) => {
    if (!demaindId) {
      // Position leg
      return TaskBarShape.round;
    } else if (fleetType === FleetType.offFleet) {
      return TaskBarShape.diamond;
    } else if (fleetType === FleetType.unscheduled) {
      return TaskBarShape.hashed;
    }
    return TaskBarShape.square;
  };