import { gantt } from 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/skins/dhtmlxgantt_material.css';
import React, { useEffect, useRef } from 'react';
import { createTasksFromCalendars } from './helpers';
import { CalendarResponse } from './types';

interface GanttViewProps {
  calendars: CalendarResponse[];
}

const GanttView: React.FC<GanttViewProps> = ({ calendars }) => {
  const ganttContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ganttContainer.current) {
      gantt.init(ganttContainer.current);
    }

    return () => {
      gantt.clearAll();
    };
  }, []);

  useEffect(() => {
    const tasks = createTasksFromCalendars(calendars);
    gantt.clearAll();
    gantt.parse({ data: tasks});
  }, [calendars]);

  return <div ref={ganttContainer} className="h-[90vh] w-screen"></div>;
};

export default GanttView;
