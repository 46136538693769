import { Group } from 'api/types';
import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { ALL_GROUP_ID, mergeGroupsToOne } from './constants';
import { ViewMode } from './ViewTabs';

interface ContextType {
  viewMode: ViewMode;
  setViewMode: (mode: ViewMode) => void;
  selectedScenarioId: string;
  setSelectedScenarioId: (id: string) => void;
  selectedGroupId: string;
  setSelectedGroupId: (id: string) => void;
  selectedCrewGroupId: string;
  setSelectedCrewGroupId: (id: string) => void;
  selectedCombineGroupId: string;
  setSelectedCombineGroupId: (id: string) => void;
}

const SelectedContext = createContext<ContextType | undefined>(undefined);

export const SelectedProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedGroupId, setSelectedGroupId] = useState(''); // Aircraft groups
  const [selectedCrewGroupId, setSelectedCrewGroupId] = useState(''); // Crew groups
  const [selectedCombineGroupId, setSelectedCombineGroupId] = useState(''); // Combine groups
  const [selectedScenarioId, setSelectedScenarioId] = useState(''); // Scenario
  const [viewMode, setViewMode] = useState(ViewMode.Aircraft);
  return (
    <SelectedContext.Provider
      value={{
        viewMode,
        setViewMode,
        selectedScenarioId,
        setSelectedScenarioId,
        selectedGroupId,
        setSelectedGroupId,
        selectedCrewGroupId,
        setSelectedCrewGroupId,
        selectedCombineGroupId,
        setSelectedCombineGroupId,
      }}
    >
      {children}
    </SelectedContext.Provider>
  );
};

export const useSelectedGroup = (): ContextType => {
  const context = useContext(SelectedContext);
  if (!context) {
    throw new Error('useSelectedGroup must be used within a SelectedProvider');
  }
  return context;
};

export function useGroupSelection(groups: Group[], selectedId: string | null) {
  return useMemo(() => {
    const isAllGroupSelected = selectedId === ALL_GROUP_ID || !selectedId;
    const selectedGroup = isAllGroupSelected
      ? mergeGroupsToOne(groups)
      : (groups.find((group) => group.id === selectedId) as Group);

    return {
      selectedGroup
    };
  }, [groups, selectedId]);
}

export const findGroupLabel = (selectedGroupId: string, groups: Group[]) => {

  const index = groups.filter((group) => group.changes > 0).findIndex((group) => group.id === selectedGroupId)
return index > -1 ? String(index + 1).padStart(2, '0') : index

}
