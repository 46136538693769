import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../routes/AuthContext'; // Adjust the import path as needed

const Login: React.FC = () => {
  const { isAuthenticated, isLoading, error, redirectPath } = useAuth();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (isAuthenticated) {
    const path = redirectPath === '/' ? '/planner' : redirectPath;
    return <Navigate to={path} replace />;
  }

  return <div>Please log in via the provided link.</div>;
};

export default Login;
