import { createPromotion, PostPromotePayload } from 'api/axios';
import { PromoteIcon, RefreshIcon } from 'assets/Icons';
import { AxiosError } from 'axios';
import { useSelectedGroup, ViewMode } from 'components/GroupNavigation';
import { AlertVariant } from 'components/shared/Alert';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Alert, Button } from '../shared';

type PromotePayload = {
  scenarioId: string;
  groupId?: string;
  combineGroupId?: string;
};

const PromoteButton = () => {
  const { selectedScenarioId, selectedGroupId, selectedCombineGroupId, viewMode } =
    useSelectedGroup();
  const [error, setError] = useState<string | null>();
  const [showCelebration, setShowCelebration] = useState(false);

  const { mutate: postPromotion, isLoading } = useMutation(
    (request: PostPromotePayload) => createPromotion(request),
    {
      retry: false,
      onSuccess() {
        setError(null);
        setShowCelebration(true);
      },
      onError(error: AxiosError<{error?: string}>) {
        if (typeof error.response?.data?.error === 'string') {
          setError(error.response.data.error);
        } else {
          setError(error.message || 'An error occurred');
        }
      }
    }
  );

  useEffect(() => {
    if (showCelebration) {
      const timer = setTimeout(() => {
        setShowCelebration(false);
      }, 3000); // Dismiss after 3 seconds

      return () => clearTimeout(timer);
    }
  }, [showCelebration]);

  const handlePromote = () => {
    const payload: PromotePayload = { scenarioId: selectedScenarioId };
    if (viewMode === ViewMode.Aircraft) {
      payload.groupId = selectedGroupId;
    } else if (viewMode === ViewMode.Combined) {
      payload.combineGroupId = selectedCombineGroupId;
    }

    postPromotion(payload);
  };

  const handleRefreshDatabase = () => {
    console.log('Refreshing database...');
    handlePromote();
  };

  const handleDismissError = () => {
    setError(null);
  };

  return (
    <>
      <Alert
        isOpen={!!error}
        onClose={handleDismissError}
        message={error || 'Something went terribly wrong'}
        title="Promote Error"
        variant={AlertVariant.Error}
      >
        
        <p className="text-sm text-ganttGrey">
          Try refreshing the database.
        </p>
        <div className="mt-4 flex justify-end space-x-2">
          <Button onClick={handleDismissError} variant="secondary">
            Dismiss
          </Button>
          <Button onClick={handleRefreshDatabase} variant="primary" className="flex items-center">
            <RefreshIcon className="mr-2 text-white"/>Refresh</Button>
        </div>
      </Alert>
      <Alert
        isOpen={showCelebration}
        onClose={() => setShowCelebration(false)}
        message="✨ Your changes have been promoted successfully. ✨"
        title="Promotion Successful"
      />
      <button
        className="rounded-lg bg-savingsGreen p-0.5 disabled:bg-cerebralGrey"
        onClick={handlePromote}
        disabled={isLoading}
      >
        {isLoading ? <RefreshIcon className="animate-spin" /> : <PromoteIcon />}
      </button>
    </>
  );
};

export default PromoteButton;
