import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Fragment } from 'react';


export enum AlertVariant {
  Success = 'success',
  Error = 'error',
  Info = 'info'
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  message: string;
  variant?: AlertVariant;
  children?: React.ReactNode;
}

const Alert = ({ isOpen, onClose,  title, message, variant, children }: Props) => {
  const getVariantStyles = () => {
    switch (variant) {
      case 'success':
        return 'bg-green-50 text-green-800';
      case 'error':
        return 'bg-white text-red-700';
      case 'info':
        return 'bg-blue-50 text-blue-800';
      default:
        return 'bg-white text-veniceBlue';
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={onClose}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className={`w-full max-w-md overflow-hidden rounded-2xl p-6 text-left align-middle shadow-xl transition-all ${getVariantStyles()}`}>
                <DialogTitle
                  as="h3"
                  className="text-lg font-medium leading-6"
                >
                  {title}
                </DialogTitle>
                <div className="my-2">
                  <p>
                    {message}
                  </p>
                </div>

                {children}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Alert;
