import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition
} from '@headlessui/react';
import { Entry, RunStatus } from 'api/types';
import { ExpandIcon } from 'assets/Icons';
import clsx from 'clsx';
import { formatRelative } from 'date-fns';
import { formatDatetimeRange } from 'helpers/utils';
import { useMemo } from 'react';

type Props = {
  entries: Entry[];
  selectedId: string;
  onSelectId: (id: string) => void;
};

const ScenarioSelector = ({ entries, selectedId, onSelectId }: Props) => {
  const validEntries = entries.filter((e) => e.exitStatus === RunStatus.success);
  const now = new Date();
  const selectedEntry = useMemo(
    () => validEntries.find((entry) => entry.id === selectedId),
    [selectedId, validEntries]
  );

  return (
    <div className="absolute right-14 top-2">
      <Listbox value={selectedId} onChange={onSelectId}>
        <div className="relative mx-4 flex max-w-md items-baseline text-veniceBlue">
          <ListboxButton className="relative flex cursor-default items-center rounded-full bg-cerebralGrey px-4 py-1.5 text-left text-lg">
            {selectedEntry ? (
              <>
                <span className='mr-4'>{selectedEntry.username}</span>
                <span>
                  {formatDatetimeRange(
                    selectedEntry.planningWindowStart,
                    selectedEntry.planningWindowEnd
                  )}
                </span>
              </>
            ) : (
              <Label className="text-sm">Select scenario</Label>
            )}
            <ExpandIcon className="ml-3 w-6" />
          </ListboxButton>
          <Transition
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <ListboxOptions className="absolute z-20 mt-10 max-h-80 w-full divide-y overflow-auto rounded-lg bg-culturedBlush focus:outline-none">
              {validEntries.map((entry) => (
                <ListboxOption
                  key={entry.id}
                  className={({ active }) =>
                    clsx('relative cursor-default select-none px-4 py-2', active && 'bg-white/90')
                  }
                  value={entry.id}
                >
                  {({ selected }) => (
                    <>
                      <p
                        className={clsx(
                          'flex justify-between truncate',
                          selected ? 'font-medium' : 'font-normal'
                        )}
                      >
                        <span>{entry.username}</span>{' '}
                        {formatDatetimeRange(entry.planningWindowStart, entry.planningWindowEnd)}
                      </p>
                      <p className="block text-sm text-gray-500">
                        <span>{formatRelative(entry.runStart, now)}</span>
                      </p>
                    </>
                  )}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default ScenarioSelector;
