import type { Crew, Group, Leg } from 'api/types';
import CostSavingsReceipt from '../CostSavingsReceipt/CostSavingsReceipt';
import GroupSelection from './GroupSelection';
import LabelText from './LabelText';
import { findGroupLabel, useGroupSelection, useSelectedGroup } from './SelectedContext';

type Props = {
  groups: Group[];
  legs: Leg[];
  crews: Crew[];
};

const getUniqueTailNumbers = (legs: Leg[]): string[] =>
  Array.from(new Set(legs.map((leg) => leg.tailNumber))).sort();

const getUniqueCrews = (crews: Crew[]): Crew[] =>
  Array.from(
    new Map(
      crews.map((crew) => [`${crew.crewId}-${crew.first}-${crew.last}-${crew.tailNumber}`, crew])
    ).values()
  ).sort((a, b) => a.crewId.localeCompare(b.crewId));

const CrewList = ({ crews }: { crews: Crew[] }) => (
  <ul className="max-h-48 min-h-20 overflow-y-scroll">
    {crews.map((crew) => (
      <li key={`${crew.crewId}-${crew.tailNumber}`}>
        {crew.first} {crew.last} <span className="text-xs italic">{crew.tailNumber}</span>
      </li>
    ))}
  </ul>
);

const TailNumberList = ({ tailNumbers }: { tailNumbers: string[] }) => (
  <ul className="max-h-48 min-h-20 overflow-y-scroll">
    {tailNumbers.map((tailNumber) => (
      <li key={tailNumber}>{tailNumber}</li>
    ))}
  </ul>
);

const CombineViewNavigation = ({ groups, legs, crews }: Props) => {
  const { selectedCombineGroupId, setSelectedCombineGroupId } = useSelectedGroup();
  const { selectedGroup } = useGroupSelection(groups, selectedCombineGroupId);
  const { legIds, crewIds } = selectedGroup;

  const displayLegs = legIds.map((legId) => legs.find((leg) => leg.id === legId)) as Leg[];
  const displayCrews = crewIds.map((crewId) => crews.find((crew) => crew.id === crewId)) as Crew[];

  const tailNumbers = getUniqueTailNumbers(displayLegs);
  const uniqueCrews = getUniqueCrews(displayCrews);

  const selectedIndex = findGroupLabel(selectedCombineGroupId, groups)

  const isAllGroup = selectedIndex === -1 
  const AircraftLabel = isAllGroup ? 'All aircraft' : `Aircraft in Group ${selectedIndex}`;
  const CrewLabel = isAllGroup ? 'All crew' : `Crew in group ${selectedIndex}`;

  return (
    <>
      <section className="flex max-h-[58vh] grow flex-col gap-2 overflow-y-scroll rounded-b-lg bg-culturedBlush p-4">
        <GroupSelection
          groups={groups}
          selectedId={selectedCombineGroupId}
          setSelectedId={setSelectedCombineGroupId}
        />
        <LabelText text={AircraftLabel} />
        <TailNumberList tailNumbers={tailNumbers} />
        <LabelText text={CrewLabel} />
        <CrewList crews={uniqueCrews} />
      </section>
      <CostSavingsReceipt group={selectedGroup as Group} />
    </>
  );
};

export default CombineViewNavigation;
