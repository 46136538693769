import { formatDuration, isSameDay, parseISO } from "date-fns";
import { formatInTimeZone } from 'date-fns-tz';

export const castToNumber = (value: number | string): number => {
  if (typeof value === "string") {
    return parseFloat(value);
  }
  return value;
};

export const calculateSaving = (mainCost: number|string, proposedCost: number|string) => {
  mainCost = castToNumber(mainCost);
  proposedCost = castToNumber(proposedCost);
  const difference = mainCost - proposedCost;
  const percentageSaved = (difference / mainCost) * 100;

  return {
    percentage: `${percentageSaved.toFixed(2)}%`, 
    value: convertToUSD(difference)
  };
}

export const convertToUSD = (value: number|string): string => {
  value = castToNumber(value);
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};

export function calculateDuration(startDate: Date | string, endDate: Date | string): string {
  const msInSecond = 1000;
  const msInMinute = msInSecond * 60;
  const msInHour = msInMinute * 60;

  const start = typeof startDate == 'string' ? parseISO(startDate): startDate;
  const end = typeof endDate == 'string' ? parseISO(endDate): endDate;

  const difference = end.getTime() - start.getTime();

  const hours = Math.floor(difference / msInHour);
  const minutes = Math.floor((difference % msInHour) / msInMinute);
  const seconds = Math.floor((difference % msInMinute) / msInSecond);

  return formatDuration({ hours, minutes, seconds });
}

export function formatDatetimeRange(start: string|Date, end: string|Date, verbose?:boolean): string {
  const startDate = toDate(start);
  const endDate = toDate(end);

  if (isSameDay(startDate, endDate)) {
    // Format: "MMM do, HH:mm - HH:mm" for the same day
    if(verbose) return `${formatDatetimeUTC(startDate)} - ${formatTimeUTC(endDate)}`;
    return `${formatDatetimeUTC(startDate, true)} - ${formatTimeUTC(endDate)}`;
  } else {
    // Format: "MM/dd HH:mm - MM/dd HH:mm" for different days
    if(verbose) return `${formatDatetimeUTC(startDate)} - ${formatDatetimeUTC(endDate)}`;
    return `${formatDatetimeUTC(startDate, true)} - ${formatDatetimeUTC(endDate, true)}`;
  }
}

export const toDate = (value: string | Date): Date => {
  return typeof value === 'string' ? new Date(value) : value;
}

const formatTimeUTC = (date: Date) => {
  return formatInTimeZone(date, 'UTC', "HH:mm")
}

export const formatDatetimeUTC = (date: Date, short=false): string => {
  if(short) return formatInTimeZone(date, 'UTC', "MM/dd HH:mm")
  return formatInTimeZone(date, 'UTC', "MMM do, HH:mm")
}

export const countLabel = (count: number, label: string) => {
  if(count === 0) return null;
  return `${count} ${label}`
}

export const removeDuplicates = <T>(array: T[]) => {
  return Array.from(new Set(array));
}

export enum PlanningStatus{
  past = "expired", 
  present = "in progress",
  future = "upcoming"
}

export const getPlanningWindowStatus = (start: string, end: string) => {
  const now = new Date(); 
  const startUTC = new Date(start);
  const endUTC = new Date(end);

  if (now < startUTC) return PlanningStatus.future
  if (now >= startUTC && now <= endUTC) return PlanningStatus.present
  if (now > endUTC) return PlanningStatus.past
};