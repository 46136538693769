import { CrewStatus } from "./constants";
import type { Crew } from "./types";

export const removeDuplicateCrew = (crewList: Crew[]) => {
    return Object.values(
      crewList.reduce(
        (acc, crew) => {
          acc[crew.crewId as string] = crew;
          return acc;
        },
        {} as Record<string, Crew>
      )
    );
  };
  
  export const findCrewsForAircraft = (crews: Crew[], tailNumber: string) => {
    const crewOrderByPos = crews
      .filter((crew) => crew.tailNumber === tailNumber)
      .sort((a, b) => a.pos - b.pos);
    return removeDuplicateCrew(crewOrderByPos);
  };
  
  export const countCrewStatuses = (crewList: Crew[]) => {
    return crewList.reduce(
      (acc, crew) => {
        if (crew.status === CrewStatus.reassigned) acc.numberReassigned++;
        if (crew.status === CrewStatus.new) acc.numberNew++;
        if (crew.status === CrewStatus.deleted) acc.numberDeleted++;
        return acc;
      },
      { numberReassigned: 0, numberNew: 0, numberDeleted: 0 }
    );
  };
