export enum AirportCode {
    KCMH = 'KCMH',
    KFPR = 'KFPR',
    KSUA = 'KSUA',
    KBHM = 'KBHM',
    KBFI = 'KBFI',
    KBDL = 'KBDL',
    KPHX = 'KPHX',
    KTEB = 'KTEB',
    KSFO = 'KSFO',
    KBUR = 'KBUR',
    KVNY = 'KVNY',
    KHWD = 'KHWD',
    KSNA = 'KSNA',
    KFTY = 'KFTY',
    KHOU = 'KHOU',
    KDAL = 'KDAL',
    KSAT = 'KSAT',
    KPDK = 'KPDK',
    KSDL = 'KSDL',
    KMSP = 'KMSP',
    KSTP = 'KSTP',
    KPBI = 'KPBI',
    KFLL = 'KFLL',
    KOPF = 'KOPF',
    KSRQ = 'KSRQ',
    KTPA = 'KTPA',
    KJFK = 'KJFK',
    KLGA = 'KLGA',
    KEWR = 'KEWR',
    KBOS = 'KBOS',
    KBED = 'KBED',
    KBTV = 'KBTV',
    KASH = 'KASH',
    KALB = 'KALB',
    KSUN = 'KSUN',
    KPIE = 'KPIE',
    KRSW = 'KRSW',
    KAPF = 'KAPF',
    KBWI = 'KBWI',
    KDCA = 'KDCA',
    KIAD = 'KIAD',
    KHEF = 'KHEF',
    KMRB = 'KMRB',
    KJYO = 'KJYO',
    KFDK = 'KFDK',
    KMTN = 'KMTN',
    KHGR = 'KHGR',
    KELD = 'KELD',
    KTXK = 'KTXK',
    KADF = 'KADF',
    KLLQ = 'KLLQ',
    KDEQ = 'KDEQ',
    KBQK = 'KBQK',
    KSSI = 'KSSI',
    KSAV = 'KSAV',
    KTLH = 'KTLH',
    KORL = 'KORL',
    KMCO = 'KMCO',
    KSFB = 'KSFB',
    KSGJ = 'KSGJ',
    KVQQ = 'KVQQ',
    KJAX = 'KJAX',
    KNIP = 'KNIP'
  }