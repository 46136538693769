import axios from 'axios';
import { CalendarResponse } from 'components/CalendarView/types';
import ICAL from 'ical.js';
import { BundleFixtures } from './fixtures';
import type { Crew, Entry, Leg, OptimizedResult } from './types';

const API_URL = process.env.REACT_APP_API_URL;

export const axiosInstance = axios.create({
  baseURL: API_URL
});

async function handleRequest<T>(request: Promise<{ data: T }>): Promise<T> {
  try {
    const response = await request;
    return response.data;
  } catch (error) {
    console.error('There was an error!', error);
    throw error;
  }
}

export const getRecentEntries = (limit = 10) => {
  return handleRequest<Entry[]>(axiosInstance.get('/compare/recent', { params: { limit } }));
};

export const getEntryById = (id: string) => {
  return handleRequest<OptimizedResult>(axiosInstance.get(`/compare/${id}`));
};

export type PostOptimizationPayload = {
  start: Date;
  duration: number;
  refreshDatabase: boolean;
};

export const postValidateToken = (token: string) => {
  return handleRequest(axiosInstance.post('/validate-token', { token }));
};

export const createOptimization = (data: PostOptimizationPayload) => {
  return handleRequest(axiosInstance.post('/optimize/generate', data));
};

export type PostPromotePayload = {
  scenarioId: string;
  groupId?: string;
  combineGroupId?: string;
};

export const createPromotion = (data: PostPromotePayload) => {
  return handleRequest(axiosInstance.post('/promote', data));
};

export const generateDummyData = () => {
  return handleRequest<{
    legs: Leg[];
    crews: Crew[];
  }>(
    new Promise((resolve) => {
      const data = BundleFixtures();
      resolve({ data });
    })
  );
};

export const getJetInsightCalendar = async () => {
  try {
    const responses = await axiosInstance.get('/calendars');
    const calendarData = responses.data as CalendarResponse[];
    calendarData.forEach((response) => {
      const jcalData = ICAL.parse(response.data);
      const comp = new ICAL.Component(jcalData);
      const vevents = comp.getAllSubcomponents('vevent');

      const events = vevents.map((vevent) => {
        const event = new ICAL.Event(vevent);
        return {
          summary: event.summary,
          description: event.description,
          start: event.startDate.toJSDate(),
          end: event.endDate.toJSDate(),
          id: event.uid,
          location: event.location
          // Add other properties 
        };
      });
      response.events = events;
    });
    return calendarData;
  } catch (error) {
    console.error('There was an error!', error);
    throw error;
  }
};
