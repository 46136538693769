import type { BaseGroup, Crew, Leg, OptimizedResult } from 'api/types';
import {
  AircraftViewNavigation,
  ALL_GROUP_ID,
  CrewViewNavigation,
  useSelectedGroup,
  ViewMode,
  ViewTabs
} from 'components/GroupNavigation';
import CombineViewNavigation from 'components/GroupNavigation/CombineViewNavigation';
import { differenceInDays } from 'date-fns';

import { useMemo } from 'react';
import GanttChart from './GanttChart';
import { findDisplayGroup, findListItemsFromIds } from './helpers';



type Props = {
  data: OptimizedResult;
}

const ChartContainer = ({data }: Props): JSX.Element => {
  
  const { crewGroups, legGroups, combinedGroups, legs, crews, dateStart, dateEnd } = data
  
  const planningDuration = differenceInDays(dateEnd, dateStart);

  const { viewMode, selectedGroupId, selectedCrewGroupId, selectedCombineGroupId } =
    useSelectedGroup();

  const { newCrews, newLegs } = useMemo(() => {
    // Determine the display group based on the view mode
    let displayGroup: BaseGroup = findDisplayGroup(selectedGroupId, legGroups);

    if (viewMode === ViewMode.Crews) {
      displayGroup = findDisplayGroup(selectedCrewGroupId, crewGroups);
    } else if (viewMode === ViewMode.Combined){
      displayGroup = findDisplayGroup(selectedCombineGroupId, combinedGroups);
    }else if (selectedGroupId === ALL_GROUP_ID) {
      return { newCrews: crews, newLegs: legs };
    }

    // Extract the selected crews and legs based on the display group
    const { crewIds, legIds } = displayGroup;
    const selectedCrews = findListItemsFromIds(crewIds, crews)
    const selectedLegs = findListItemsFromIds(legIds, legs)

    return { newCrews: selectedCrews, newLegs: selectedLegs, selectedGroup: displayGroup };
  }, [viewMode, selectedGroupId, selectedCrewGroupId, selectedCombineGroupId, crews, legs, crewGroups, legGroups, combinedGroups]);

  return (
    <div>
      <div className="grid grid-cols-5">
        <section className="mx-1 flex max-h-[calc(100vh-62px)] flex-col text-veniceBlue">
          {/* <p className="text-xs">{formatDatetimeRange(dateStart, dateEnd, true)}</p> */}
          <ViewTabs />

          {viewMode === ViewMode.Aircraft && <AircraftViewNavigation groups={legGroups} legs={legs}/>}
          {viewMode === ViewMode.Crews && <CrewViewNavigation groups={crewGroups} crews={crews} legs={legs} />}
          {viewMode === ViewMode.Combined && <CombineViewNavigation groups={combinedGroups} crews={crews} legs={legs} />}
        </section>
        <GanttChart
          duration={planningDuration}
          selectedGroupId={selectedGroupId}
          selectedCrewGroupId={selectedCrewGroupId}
          selectedCombineGroupId={selectedCombineGroupId}
          legs={newLegs as Leg[]}
          crews={newCrews as Crew[]}
          viewMode={viewMode}
        />
      </div>
    </div>
  );
};

export default ChartContainer;
