import { Transition } from '@headlessui/react';
import React, { ButtonHTMLAttributes } from 'react';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'tertiary';
  size?: 'small' | 'medium' | 'large';
  show?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  variant = 'primary',
  size = 'medium',
  className,
  show = true,
  ...props
}) => {
  const baseClasses = 'rounded-lg font-semibold transition-colors duration-200 ease-in-out';
  
  const variantClasses = {
    primary: 'bg-veniceBlue text-white hover:bg-veniceBlue-dark',
    secondary: 'bg-white text-veniceBlue border border-veniceBlue hover:bg-veniceBlue hover:text-white',
    tertiary: 'bg-transparent text-veniceBlue hover:bg-veniceBlue hover:text-white',
  };

  const sizeClasses = {
    small: 'px-3 py-1 text-sm',
    medium: 'px-4 py-2 text-base',
    large: 'px-6 py-3 text-lg',
  };

  const buttonClasses = `${baseClasses} ${variantClasses[variant]} ${sizeClasses[size]} ${className || ''}`;

  return (
    <Transition
      show={show}
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <button className={buttonClasses} {...props}>
        {children}
      </button>
    </Transition>
  );
};

export default Button;
