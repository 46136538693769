import { isValid, parseISO } from 'date-fns';

export const isValidDate = (dateString: string): boolean => {
    const date = parseISO(dateString);
    return isValid(date);
  };
  
  // Issue is the Gantt chart is displaying tasks in local timezone, so we need to remove timezone for the tasks start and end value
  export const dropTimezone = (date: string):Date => {
    return new Date(date.slice(0, 19).replace('T', ' '));
  };