import clsx from 'clsx';
import React from 'react';

type Props = {
  text: string;
  variant?: 'default' | 'small' | 'plain';
  className?: string;
};

const LabelText: React.FC<Props> = ({ text, variant = 'default', className }) => {
  const baseClasses = 'text-veniceBlue';

  const variantClasses = {
    default: 'text-sm font-light uppercase',
    small: 'text-xs',
    plain: '',
  };

  const combinedClasses = clsx(baseClasses, variantClasses[variant], className);

  if (variant === 'default') {
    return (
      <div className={className}>
        <h3 className={combinedClasses}>{text}</h3>
        <hr />
      </div>
    );
  }

  return <span className={combinedClasses}>{text}</span>;
};

export default LabelText;
