import { ScenarioType } from 'api/constants';
import useToggleSwitch from 'components/Launcher/useToggleSwitch';
import { gantt } from 'dhtmlx-gantt';
import { useLayoutEffect } from 'react';
import { legTaskHeight, pilotRowLabels, taskHeightWithCrew } from '../GanttChartView/constants';


const CrewToggle = () => {
  const {checked, ToggleSwitch } = useToggleSwitch({ label: 'Show crew' });
  
  useLayoutEffect(() => {
    const onBeforeTaskDisplay = gantt.attachEvent('onBeforeTaskDisplay', function (id, task) {
      const isCrew = pilotRowLabels.includes(task.text);
      if (!checked && isCrew) {
        return false;
      }
      const isLeg = task.legId;
      const isScenario = Object.values(ScenarioType).some(type => (task.id as string).endsWith(type));
      if(isLeg || isScenario) { // Resize aircraft + scenario rows when crew is toggled
        task.row_height = checked ? taskHeightWithCrew.row_height : legTaskHeight.row_height;
        task.bar_height = checked ? taskHeightWithCrew.bar_height : legTaskHeight.bar_height;
    }
      return true;
    });
    gantt.refreshData();

    return () => {
      gantt.detachEvent(onBeforeTaskDisplay);
    };
  }, [checked]);

  return <ToggleSwitch />;
};

export default CrewToggle;
