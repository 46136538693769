import { Task } from 'dhtmlx-gantt';
import { CalendarResponse } from './types';

export function createTasksFromCalendars(calendars: CalendarResponse[]): Task[] {
  const tasks: Task[] = [];

  calendars.forEach((calendar) => {
    const parentId = `parent_${calendar.tailNumber}`;
    // Create parent task for each calendar (tail number)
    tasks.push({
      id: parentId,
      text: calendar.tailNumber,
      open: true,
      tailNumber: calendar.tailNumber
    });

    // Create child tasks for each event in the calendar
    calendar.events.forEach((event) => {
      tasks.push({
        id: event.id,
        text: event.summary,
        start_date: new Date(event.start),
        end_date: new Date(event.end),
        parent: parentId,
        tailNumber: calendar.tailNumber,
        render: 'split'
      });
    });
  });

  return tasks;
}