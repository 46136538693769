import type { Group } from 'api/types';

export const ALL_GROUP_ID = 'all';

export const mergeGroupsToOne = (groups: Group[]) => {
  return groups.reduce(
    (acc, { changes, legIds, crewIds, costs, offFleetCosts }) => {
      acc.changes += changes;
      acc.costs.existing += Number(costs.existing);
      acc.costs.optimized += Number(costs.optimized);
      acc.offFleetCosts.existing += Number(offFleetCosts.existing);
      acc.offFleetCosts.optimized += Number(offFleetCosts.optimized);
      acc.legIds.push(...legIds);
      acc.crewIds.push(...crewIds);
      return acc;
    },
    {
      id: ALL_GROUP_ID,
      changes: 0,
      legIds: [], 
      crewIds: [],
      costs: {
        existing: 0,
        optimized: 0,
      },
      offFleetCosts: {
        existing: 0,
        optimized: 0,
      },
    } as Group
  );
};
