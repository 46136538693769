import React from 'react';
import companyName from '../../assets/logo/company_name.png';
import logo from '../../assets/logo/logo.png';

const services = [
  "Aircraft scheduling optimization",
  "Crew scheduling optimization",
  "Multi-day trip sequence optimization",
  "Pricing and revenue management",
  "Automated trip quoting using GenAI and optimization",
  "Flight time calculator",
]

const mailTo = 'mailto:info@floatingfleet.ai';

const LandingPage: React.FC = () => {
  return (
    <div className="flex min-h-screen flex-col text-veniceBlue">
      <div className="flex min-h-[90vh] grow flex-col items-center justify-center space-y-12 p-4 md:min-h-0 md:space-y-24">
        <img src={logo} alt="Optimized cloud" className="h-auto w-32 md:w-52" />
        <img src={companyName} alt="Floating Fleet ai" className="h-auto w-48 md:w-64" />
      
        <p className="atlas-font mx-auto max-w-prose text-left text-base md:text-xl">
          We provide transparent and trustworthy AI solutions to the floating fleet operators and
          brokers in private aviation. 
          In October, 2024, we are launching an AI Planner to address the aircraft and crew scheduling
          optimization. We will be attending <span className='hover:font-bold'>NBAA-BACE</span> in Las Vegas. <a href={mailTo} className='hidden underline hover:font-medium md:block'>Please reach out for a demo.</a>
          <button className='my-4 rounded-md bg-veniceBlue px-4 py-2 text-white md:hidden' onClick={() => window.location.href = mailTo}>Request a Demo</button>
        </p>
      </div>
      <div className="fugue-font atlas-font px-4 pb-8 md:hidden">
          <h3 className="mb-2 text-base uppercase">Our Services</h3>
          <ul className="text-medium space-y-1 border-y-4 border-veniceBlue py-3 text-xl">
            {services.map((service, index) => (
              <li key={index}>{service}</li>
            ))}
          </ul>
          <hr/>
        </div>

      <div className="fugue-font group mt-auto hidden overflow-hidden bg-veniceBlue py-3 text-white md:block">
        <div className="animate-marquee whitespace-nowrap text-3xl group-hover:animate-marquee-pause">
          <span className="mx-4">
            {services.map((service, index) => (
              <React.Fragment key={index}>
                {index > 0 && <span className="mx-3">•</span>}
                <span>{service}</span>
              </React.Fragment>
            ))}
          </span>
          <span className="mx-4">
            {services.map((service, index) => (
              <React.Fragment key={index}>
                {index > 0 && <span className="mx-3">•</span>}
                <span>{service}</span>
              </React.Fragment>
            ))}
          </span>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
