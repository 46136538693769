import React, { createContext, useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { postValidateToken } from '../api/axios';
interface AuthContextType {
  isAuthenticated: boolean;
  login: (token: string) => Promise<void>;
  logout: () => void;
  isLoading: boolean;
  error: string | null;
  redirectPath: string;
  setRedirectPath: (path: string) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState('/');

  const {
    mutate: validateToken,
    isLoading,
    error
  } = useMutation((token: string) => postValidateToken(token), {
    onSuccess: (data) => {
      setIsAuthenticated(true);
      const token = (data as { token: string }).token;
      localStorage.setItem('authToken', token);
    },
    onError: (error) => {
      console.error('Token validation failed:', error);
      setIsAuthenticated(false);
      localStorage.removeItem('authToken');
    }
  });

  useEffect(() => {
    const storedToken = localStorage.getItem('authToken');
    if (storedToken) {
      login(storedToken);
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      const urlToken = urlParams.get('token');
      if (urlToken) {
        login(urlToken);
      }
    }
  }, []);

  const login = async (token: string) => {
    try {
      await validateToken(token);
      window.history.replaceState({}, document.title, window.location.pathname);
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('authToken');
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        login,
        logout,
        isLoading,
        error: error ? (error as Error).message : null,
        redirectPath,
        setRedirectPath
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
