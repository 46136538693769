import Header from 'components/Header/Header';
import { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';

interface ProtectedRouteProps {
  authenticationPath: string;
}

export default function ProtectedRoute({ authenticationPath }: ProtectedRouteProps) {
  const { isAuthenticated, setRedirectPath } = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (!isAuthenticated) {
      const currentPath = location.pathname + location.search;
      setRedirectPath(currentPath);
    }
  }, [isAuthenticated]);

  if (isAuthenticated) {
    return (
      <>
        <Header />
        <Outlet />
      </>
    );
  }

  return <Navigate to={authenticationPath} />;
}
