import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Paths = [
  { to: '/compare-dummy', name: '15 scenarios' },
  { to: '/calendar', name: 'Calendar' },
  { to: '/profile', name: 'Profile' },
  { to: '/settings', name: 'Settings' }
];

const Header: React.FC = () => {
  const location = useLocation();

  const getLinkClass = (path: string) => {
    return location.pathname === path
      ? 'font-semibold text-veniceBlue'
      : 'text-veniceBlue/50 hover:text-veniceBlue hover:font-semibold';
  };

  return (
    <header className="flex items-center justify-between bg-helles px-4 py-2">
      <nav>
        <ul className="flex space-x-6">
          <li>
            <Link to="/planner" className={getLinkClass('/planner')}>
              AI Planner
            </Link>
          </li>
          <li>
            <Link to="/compare-schedule" className={getLinkClass('/compare-schedule')}>
              GanttIn$ight
            </Link>
          </li>
        </ul>
      </nav>
      <div className="flex items-center">
        <Menu as="div" className="relative">
          <MenuButton className="size-10 rounded-full bg-veniceBlue/20 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            {/* Placeholder for user avatar */}
          </MenuButton>
          <MenuItems className="absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md bg-culturedBlush py-1 shadow-lg ring-1 ring-black/5 focus:outline-none">
            {Paths.map((path) => (
              <MenuItem key={path.name}>
                {({ active }) => (
                  <Link
                    to={path.to}
                    className={`${
                      active ? 'bg-cerebralGrey/50' : ''
                    } block px-4 py-2 text-sm text-veniceBlue`}
                  >
                    {path.name}
                  </Link>
                )}
              </MenuItem>
            ))}
          </MenuItems>
        </Menu>
      </div>
    </header>
  );
};

export default Header;
