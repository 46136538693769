export interface ChatMessage {
  type: 'user' | 'bot';
  text: string;
  timestamp: Date;
}

export enum SocketEvents {
  ChatResponse = 'chat-response',
  EngineResponse = 'engine-response',
  ChatError = 'chat-error',
  QuoteResponse = 'quote-response'
}

export type FlightDetails = {
  departureAirport: string;
  arrivalAirport: string;
  departureTime: string;
  aircraftType: string;
  timeFlexibility: number;
};

export interface FlightChatResponse {
  message: string;
  flightDetails?: FlightDetails;
  complete: boolean;
}
