import CalendarView from 'components/CalendarView/CalendarView';
import DataWrapper from 'components/GanttChartView/DataWrapper';
import DummyChart from 'components/GanttChartView/DummyChart';
import { SelectedProvider } from 'components/GroupNavigation/SelectedContext';
import LandingPage from 'components/Landing/LandingPage';

import Launcher from 'components/Launcher';
import Login from 'components/Login/Login';
import NotFoundPage from 'components/NotFound/NotFound';
import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements, Route,
  RouterProvider
} from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';

const Routes: React.FC = () => {

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" errorElement={<NotFoundPage />}
      >
        <Route index element={<LandingPage />} /> 
        <Route path="login" element={<Login />} />
        <Route
          element={<ProtectedRoute authenticationPath="/login" />}
        >
            <Route path="planner" element={<Launcher />} />
            <Route path="compare-dummy" element={<DummyChart />} />
            <Route path="calendar" element={<CalendarView />} />
            <Route
              path="compare-schedule"
              element={
                <SelectedProvider>
                  <DataWrapper />
                </SelectedProvider>
              }
            />
        </Route>
      </Route>
    )
  );

  return <RouterProvider router={router} />;
};

export default Routes;
