import { Entry, RunStatus } from 'api/types';
import clsx from 'clsx';
import { formatRelative } from 'date-fns';
import { formatDatetimeRange } from 'helpers/utils';
import { Link } from 'react-router-dom';

type Prop = {
  entries: Entry[] | undefined;
};

const statusClassNames = (status: RunStatus) => {
  switch (status) {
    case RunStatus.success:
      return 'bg-green-500';
    case RunStatus.error:
      return 'bg-red-500';
  }
};

const EntryRow = ({ entry }: { entry: Entry }) => {
  const now = new Date();
  return (
    <>
      <p className="text-xs text-gray-500">{formatRelative(entry.runStart, now)}</p>
      <span className='font-medium'>{entry.username}</span>
      <span className='col-span-2 text-gray-800'>
        {formatDatetimeRange(entry.planningWindowStart, entry.planningWindowEnd)}
      </span>
      <span className={clsx(statusClassNames(entry.exitStatus), 'w-min rounded-md px-1 py-0.5 text-xs font-medium text-white')}>
        {entry.exitStatus}
      </span>
    </>
  );
};

const EntryList = ({ entries }: Prop) => {
  if (!entries) return <h1>No historical runs</h1>;

  const rowClass = 'grid grid-cols-5 items-center gap-2 text-sm px-4 py-1'
  return (
    <section className='mx-4 rounded-lg bg-culturedBlush'>
      <div>
        <h2 className='m-4 font-light uppercase'>Recent runs</h2>
        <div className='grid grid-cols-5 items-center gap-2 px-4 pb-2 text-sm font-medium text-veniceBlue'>
          <span>Runtime</span>
          <span>User</span>
          <span className='col-span-2'>Planning window</span>
          <span>Status</span>
        </div>
      </div>
      <ul className="mb-4 h-min max-h-[calc(100vh-154px)] divide-y overflow-y-scroll">
        {entries.map((entry) => (
          <li key={entry.id} >
            {entry.exitStatus === RunStatus.error ? (
              <div className={clsx(rowClass, 'opacity-70')}>
                <EntryRow entry={entry} />
              </div>
            ) : (
              <Link to={`/compare-schedule?id=${entry.id}`} className={clsx(rowClass, 'hover:bg-amber-100')}>
                <EntryRow entry={entry} />
              </Link>
            )}
          </li>
        ))}
      </ul>
    </section>
  );
};

export default EntryList;
