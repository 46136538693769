import { faker } from '@faker-js/faker';

export const getRandomEnumElement = <T>(o: { [s: string]: T }): T => {
  return faker.helpers.arrayElement(Object.values(o));
};

export const getOptionalValue = <T>(value: T | undefined): T | undefined => {
  return faker.datatype.boolean() ? value : undefined;
};


export const roundToNearestHalfHour = (date: Date, roundUp: boolean): Date  => {
  const minutes = date.getMinutes();
  const remainder = minutes % 30;

  if (remainder === 0) {
    // Already on the half-hour
    return date;
  }

  if (roundUp) {
    // Round up to the next half-hour
    const roundedMinutes = remainder < 30 ? 30 : 0;
    date.setMinutes(roundedMinutes, 0, 0);

    if (roundedMinutes === 0) {
      // If rounding up to the next hour
      date.setHours(date.getHours() + 1);
    }
  } else {
    // Round down to the previous half-hour
    const roundedMinutes = remainder < 30 ? 0 : 30;
    date.setMinutes(roundedMinutes, 0, 0);
  }

  return date;
}
