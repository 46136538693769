import { MinusIcon, PlusIcon } from 'assets/Icons';
import { GanttStatic } from 'dhtmlx-gantt';

const ZoomControl = ({ gantt }: { gantt: GanttStatic }) => {
  return (
<div className="absolute right-2 z-10 bg-culturedBlush px-5 pb-3">
    <div className="flex justify-end gap-2">
    <button
      onClick={() => {  
        gantt.ext.zoom.zoomOut()
        gantt.refreshData();
      }}
      className="rounded-full bg-ganttGrey/70 hover:bg-white/50"
    >
      <MinusIcon className="text-white hover:text-veniceBlue/80" />
    </button>
      <button
        onClick={() => {
          gantt.ext.zoom.zoomIn()
          gantt.refreshData();
        }}
        className="rounded-full bg-ganttGrey/70 hover:bg-white/50"
      >
        <PlusIcon  className="text-white hover:text-veniceBlue/80"/>
      </button>
    </div>
    </div>
  )
}

export default ZoomControl;