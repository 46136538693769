import { faker } from '@faker-js/faker';
import { parseISO } from 'date-fns';
import { groupBy } from 'lodash';
import { CrewStatus, CrewType, FleetType, LegStatus, ScenarioType } from '../constants';
import type { Crew, Leg } from '../types';
import { getRandomEnumElement, roundToNearestHalfHour } from './helpers';

type CrewFixtureParams = {
  departTimeGMT: Date;
  arrivalTime: Date;
  tailNumber: string;
};

const CrewFixture = (
  { departTimeGMT, arrivalTime, tailNumber }: CrewFixtureParams,
  overrides: Partial<Crew>
): Crew => {
  const startTimeGMT = roundToNearestHalfHour(departTimeGMT, false).toISOString();
  const endTimeGMT = roundToNearestHalfHour(arrivalTime, true).toISOString();
  return {
    id: faker.string.nanoid(4),
    tailNumber,
    startTimeGMT,
    endTimeGMT,
    last: faker.person.lastName(),
    first: faker.person.firstName(),
    scenario: getRandomEnumElement(ScenarioType),
    pos: getRandomEnumElement(CrewType) as CrewType,
    status: getRandomEnumElement(CrewStatus),
    crewId: faker.string.nanoid(4),
    ...overrides
  };
};

const mapStateToCrewStatus = (state: LegStatus, scenario: ScenarioType): CrewStatus => {
  switch (state) {
    case LegStatus.unchanged:
      return CrewStatus.unchanged;
    case LegStatus.optimized:
      return CrewStatus.reassigned;
    case LegStatus.unscheduled:
      if (scenario === ScenarioType.existing) return CrewStatus.deleted;
      else return CrewStatus.new;
    default:
      return CrewStatus.unchanged;
  }
};
export const CrewPair = (
  departTimeGMT: Date,
  arrivalTime: Date,
  tailNumber: string,
  scenario: ScenarioType,
  state: LegStatus
): Crew[] => {
  const status = mapStateToCrewStatus(state, scenario);
  const crew1 = CrewFixture(
    { departTimeGMT, arrivalTime, tailNumber },
    { pos: CrewType.pilot, scenario, status }
  );
  const crew2 = CrewFixture(
    { departTimeGMT, arrivalTime, tailNumber },
    { pos: CrewType.coPilot, scenario, status }
  );
  return [crew1, crew2];
};

const groupLegsByTailNumberScenario = (legs: Leg[]) => {
  return groupBy(legs, (leg) => [leg.tailNumber, leg.scenario, leg.fleetType]);
};

const getLinkedIdPairs = (legs: Leg[]): string[][] => {
  return legs.reduce((acc, leg) => {
    if (leg.linkId) {
      acc.push([leg.id, leg.linkId]);
    }
    return acc;
  }, [] as string[][]);
};

const updateStateForOffFleetLeg = (aircrafts: Leg[], legs: Leg[], state: LegStatus): LegStatus => {
  // When leg is offFleet, then crew should be deleted in the existing scenario
  if(state === LegStatus.optimized){
      const linkedIdPairs = getLinkedIdPairs(legs);
    const demandLeg = aircrafts.find(a => a.demandId) as Leg;
    const linkedId = demandLeg?.linkId || linkedIdPairs.find(pair => pair.includes(demandLeg.id))?.find(id => id !== demandLeg?.id);
    const linkedLeg = legs.find(leg => leg.id === linkedId) as Leg;
    if (linkedLeg?.fleetType === FleetType.offFleet) {
      return LegStatus.unscheduled;
    }
  }
  return state
};

const createCrewPair = (aircrafts: Leg[], tailnumber: string, scenario: string, state: LegStatus): Crew[] => {
  const earliestDepartTimeGMT = findEarliestDepartureTime(aircrafts);
  const latestArriveTimeGMT = findLatestArrivalTime(aircrafts);
  return CrewPair(
    earliestDepartTimeGMT,
    latestArriveTimeGMT,
    tailnumber,
    scenario as ScenarioType,
    state
  );
};

export const createCrewForLegs = (legs: Leg[], state: LegStatus) => {
  const groupedByTailNumberScenario = groupLegsByTailNumberScenario(legs);
  
  return Object.keys(groupedByTailNumberScenario).map((key) => {
    const [tailnumber, scenario, fleetType] = key.split(',');
    if (fleetType !== FleetType.onFleet) return []; // only create crews for onFleet aircraft

    const aircrafts = groupedByTailNumberScenario[key];

    const updatedState =  updateStateForOffFleetLeg(aircrafts, legs, state)
    return createCrewPair(aircrafts, tailnumber, scenario, updatedState);
  });
};

const findEarliestDepartureTime = (legs: Leg[]) => {
  return legs
    .map((legs) => parseISO(legs.departTimeGMT))
    .reduce((earliest, current) => (current < earliest ? current : earliest));
};

const findLatestArrivalTime = (legs: Leg[]) => {
  return legs
    .map((legs) => parseISO(legs.arriveTimeGMT))
    .reduce((latest, current) => (current > latest ? current : latest));
};
