import { faker } from '@faker-js/faker';
import { addMinutes, differenceInMinutes, subMinutes } from 'date-fns';
import { flattenDeep, pick } from 'lodash';
import { FleetType, LegStatus, ScenarioType } from '../constants';
import type { Leg } from '../types';
import { AirportCode } from './constants';
import { getRandomEnumElement } from './helpers';

const AircraftFixture = () => ({
  aircraftType: faker.airline.airplane().name,
  tailNumber: `${faker.airline.airline().iataCode}${faker.airline.flightNumber({ addLeadingZeros: true })}` // 'AA0798'
});

export const LegFixture = (overrides: Partial<Leg>, isPosition: boolean): Leg => {
  const departTimeGMT = overrides.departTimeGMT ?? faker.date.soon().toISOString();
  const arriveTimeGMT = addMinutes(
    departTimeGMT,
    isPosition ? faker.number.int({ min: 30, max: 90 }) : faker.number.int({ min: 30, max: 60 * 6 })
  ).toISOString();

  return {
    id: faker.string.nanoid(4),
    tcGroupId: faker.string.numeric(1),
    groupId: faker.string.numeric(1),
    cost: parseInt(faker.finance.amount({ min: 0, max: 10000, dec: 0 })),
    international: faker.datatype.boolean(),
    departAirport: getRandomEnumElement(AirportCode),
    arriveAirport: getRandomEnumElement(AirportCode),
    ...AircraftFixture(),
    departTimeGMT,
    arriveTimeGMT,
    changedFlag: faker.datatype.boolean(),
    scenario: getRandomEnumElement(ScenarioType),
    demandId: !isPosition ? faker.string.nanoid(7) : undefined,
    fleetType: getRandomEnumElement(FleetType),
    ...overrides
  };
};

export const PositionDemandLegFixture = (overrides: Partial<Leg>): Leg[] => {
  const aiport = getRandomEnumElement(AirportCode);
  const positionFlight = LegFixture({ arriveAirport: aiport, ...overrides }, true);

  const departTimeGMT = addMinutes(
    positionFlight.arriveTimeGMT,
    faker.number.int({ min: 10, max: 60 })
  );
  const demandFlight = LegFixture(
    {
      departAirport: aiport,
      departTimeGMT: departTimeGMT.toISOString(),
      aircraftType: positionFlight.aircraftType,
      tailNumber: positionFlight.tailNumber,
      ...overrides
    },
    false
  );
  return [positionFlight, demandFlight];
};

// 3 pairs
// 1 unchanged (duplicate leg)
// 2. optimized (changed, optimized)
// 3. unschedule (unschedule, optimized)

export const OnFleet_OnFleet = (aircraftChanged:boolean, timeChanged: boolean) => {
  return createFixtureForCase({
    aircraftChanged,
    timeChanged,
    existingFleetType: FleetType.onFleet
  })
};


export const LegPairsSet = (state: LegStatus): Leg[] => {
  if (state === LegStatus.unchanged) {
    // 1-1-0-0 onfleet_onfleet -- no aircraft change - no time change
    const unchangedLegs = OnFleet_OnFleet(false, false);

    // Update the time for one of the position legs
    const earlier = faker.number.int({ min: 2, max: 30 });
    unchangedLegs[0].departTimeGMT = subMinutes(unchangedLegs[0].departTimeGMT, earlier).toISOString();
    unchangedLegs[0].arriveTimeGMT = subMinutes(unchangedLegs[0].arriveTimeGMT, earlier).toISOString();
    unchangedLegs[0].changedFlag = true;
    unchangedLegs.forEach((leg) => {
      if (leg.demandId) leg.changedFlag = false;
    });

    return unchangedLegs;
  } else if (state === LegStatus.optimized) {
    // 1-1 onfleet - onfleet -- aircraft - time
    // 1-1-0-1, 1-1-1-0, 1-1-1-1
    const onFleetOnFleetCases = [
      [0, 1],
      [1, 0],
      [1, 1]
    ].map(([aircraftChanged, timeChanged]) => OnFleet_OnFleet(!!aircraftChanged, !!timeChanged));

    // 1-2 onfleet - off-fleet -- aircraft - time
    // 1-2-1-0, 1-2-1-1
    const onFleetOffFleetCases = [0, 1].map((timeChanged) =>
      createFixtureForCase({
        aircraftChanged: true,
        timeChanged: !!timeChanged,
        existingFleetType: FleetType.onFleet,
        optimizedFleetType: FleetType.offFleet
      })
    );

    // 2-1 off-fleet - onfleet -- aircraft - time
    // 2-1-1-0, 2-1-1-1
    const offFleetOnFleetCases = [0, 1].map((timeChanged) =>
      createFixtureForCase({
        aircraftChanged: true,
        timeChanged: !!timeChanged,
        existingFleetType: FleetType.offFleet,
        optimizedFleetType: FleetType.onFleet
      })
    );

    // 2-2 off-fleet - off-fleet -- aircraft - time
    // 2-2-1-0, 2-2-1-1
    const offFleetOffFleetCases = [0, 1].map((timeChanged) =>
      createFixtureForCase({
        aircraftChanged: false, // No aircraft change is possible, When a demand is off-fleet, Optimizer won’t reoptimize its aircraft assignment. That is, the demand stays on the same aircraft.
        timeChanged: !!timeChanged,
        existingFleetType: FleetType.offFleet
      })
    );

    return flattenDeep([
      onFleetOnFleetCases,
      onFleetOffFleetCases,
      offFleetOnFleetCases,
      offFleetOffFleetCases
    ]);
  } else if (state === LegStatus.unscheduled) {
    // 1-3 unscheduled - onfleet -- aircraft - time
    // 1-3-1-0, 1-3-1-1
    // 3-1 unscheduled - onfleet -- aircraft - time
    // 3-1-1-0, 3-1-1-1
    const onFleetUnscheduledMatrixCases = [
      [FleetType.onFleet, FleetType.unscheduled],
      [FleetType.unscheduled, FleetType.onFleet]
    ].map(([existingFleetType, optimizedFleetType]) =>
      [0, 1].map((timeChanged) =>
        createFixtureForCase({
          aircraftChanged: false,
          timeChanged: !!timeChanged,
          existingFleetType,
          optimizedFleetType
        })
      )
    );

    // 2-3 off-fleet - unschedule -- aircraft - time
    // 2-3-1-0
    // 3-2 unscheduled - off-fleet -- aircraft - time
    // 3-2-1-0

    const offFleetUnscheduleMatrixCases = [
      [FleetType.offFleet, FleetType.unscheduled],
      [FleetType.unscheduled, FleetType.offFleet]
    ].map(([existingFleetType, optimizedFleetType]) =>
      createFixtureForCase({
        aircraftChanged: false,
        timeChanged: false,
        existingFleetType,
        optimizedFleetType
      })
    );

    return flattenDeep([onFleetUnscheduledMatrixCases, offFleetUnscheduleMatrixCases]);
  }
  return [];
};

type Case = {
  aircraftChanged: boolean;
  timeChanged: boolean;
  existingFleetType: FleetType;
  optimizedFleetType?: FleetType;
};

const createFixtureForCase = ({ existingFleetType, optimizedFleetType, ...rest }: Case): Leg[] => {
  let existingSet: Leg[] = [];
  let optimizedSet: Leg[] = [];
  const existingParams = {
    scenario: ScenarioType.existing,
    changedFlag: true,
    fleetType: existingFleetType
  };

  // From (OffFleet | Unscheduled) --> OnFleet,  [Demand] --> [Position, Demand]
  if ([FleetType.offFleet, FleetType.unscheduled].includes(existingFleetType) && optimizedFleetType === FleetType.onFleet) {
    // 1. working backwards, create a Position + Demand leg pair for the Optimized scenario
    optimizedSet = PositionDemandLegFixture({
      scenario: ScenarioType.optimized,
      changedFlag: true,
      fleetType: optimizedFleetType
    });

    // 2. Duplicate only the demand leg for the Existing scenario
    const demandLegIndex = optimizedSet.findIndex((l) => l.demandId);
    const existingLeg = LegFixture(
      {
        ...existingParams,
        ...pick(optimizedSet[demandLegIndex], [
          'departAirport',
          'arriveAirport',
          'departTimeGMT',
          'arriveTimeGMT',
        ]), 
        ...rest.aircraftChanged ? AircraftFixture() : pick(optimizedSet[demandLegIndex], ['aircraftType', 'tailNumber'])
      },
      false
    );
    // 3. Link the demand leg to the optimized demand leg
    optimizedSet[demandLegIndex].linkId = existingLeg.id;

    return [existingLeg, ...optimizedSet];
  }

  // For OnFleet type [Position, Demand], for OffFleet/Unscheduled type [Demand]
  if (existingFleetType === FleetType.onFleet) {
    existingSet = PositionDemandLegFixture(existingParams);
  } else {
    existingSet = [LegFixture(existingParams, false)];
  }

  optimizedSet = OptimizedLegsFixture({
    existingLegs: existingSet,
    fleetType: optimizedFleetType,
    ...rest
  });
  return [...existingSet, ...optimizedSet];
};

type OptimizedParams = {
  existingLegs: Leg[];
  fleetType?: FleetType;
  aircraftChanged?: boolean;
  timeChanged?: boolean;
};

const OptimizedLegsFixture = ({
  existingLegs,
  fleetType,
  aircraftChanged,
  timeChanged
}: OptimizedParams): Leg[] => {
  const demandLeg = existingLegs.find((l) => l.demandId) as Leg;
  const aircraftOverride = aircraftChanged ? AircraftFixture() : {};
  // For Unscheduled and OffFleet fleet, there's no position leg to optimize
  const legsToDuplicate = fleetType && [FleetType.offFleet, FleetType.unscheduled].includes(fleetType) ? [demandLeg] : existingLegs;
  const optimizedLegs = legsToDuplicate.map((leg, index) => {
    return {
      ...leg,
      scenario: ScenarioType.optimized,
      id: faker.string.nanoid(4),
      linkId: leg.demandId ? leg.id : undefined, // link the demand leg to the optimized leg
      fleetType: fleetType ?? leg.fleetType,
      ...aircraftOverride
    };
  });

  if (timeChanged) {
    const flightDuration = differenceInMinutes(demandLeg.arriveTimeGMT, demandLeg.departTimeGMT);
    const timeDiff = faker.number.int({ min: 5, max: 30 });
    // Randomly choose a plus/minus 5-30 minute change
    const departureTime = faker.datatype.boolean()
      ? subMinutes(demandLeg.departTimeGMT, timeDiff)
      : addMinutes(demandLeg.departTimeGMT, timeDiff);
    // Keep the same duration, unless aircraft flies faster/slower significantly
    const arrivalTime = addMinutes(departureTime, flightDuration);

    const demandIndex = optimizedLegs.findIndex((l) => l.demandId);
    optimizedLegs[demandIndex].departTimeGMT = departureTime.toISOString();
    optimizedLegs[demandIndex].arriveTimeGMT = arrivalTime.toISOString();
  }

  return optimizedLegs;
};
