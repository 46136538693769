import { OffFleetRow, ScenarioType } from 'api/constants';
import { gantt } from 'dhtmlx-gantt';
import ReactDOMServer from 'react-dom/server';
import { pilotRowLabels } from './constants';

const AircraftColumnText = (obj: { id: string | number; text: string }) => {
  const parent = gantt.getParent(obj.id);
  const aircraftTask = gantt.getTask(obj.id);
  if (!parent) return null;
  
  const parentTask = gantt.getTask(parent);
  const label = obj.text
  const isSecondRow = label === ScenarioType.existing || label === OffFleetRow
  const isPilotRow = pilotRowLabels.includes(label)

  if(isPilotRow || isSecondRow) return null

  const component = (
    <div className="absolute z-10 flex flex-col pl-2 font-bold text-veniceBlue">
      <span className="mt-2 text-xl" >{parentTask.tailNumber}</span>
      <span className="text-lg">
        {aircraftTask.aircraftType.length > 10
          ? aircraftTask.aircraftType.split(' ').pop()
          : aircraftTask.aircraftType}
      </span>
    </div>
  );

  return ReactDOMServer.renderToString(component);
};

export default AircraftColumnText;
