import React, { useState } from "react";

const ErrorMessage: React.FC<{ message: string; context?: string }> = ({ message, context }) => (
  <div className="m-4 h-min w-fit rounded border border-red-600 px-4 py-2 text-red-500">
    {context && <div className="font-medium">{context}</div>}
    {message}
  </div>
);

const useErrorHandler = (initialState = '') => {
  const [error, setError] = useState<string>(initialState);

  const handleError = (e: Error | string) => {
    setError(typeof e === 'string' ? e : e.message);
  };

  const ErrorComponent: React.FC<{ context?: string }> = ({ context }) => 
    error ? <ErrorMessage message={error} context={context} /> : null;

  return { error, handleError, ErrorComponent };
};

export default useErrorHandler;