
export enum NotificationType {
    ERROR = 'error',
    SUCCESS = 'success',
    INFO = 'info'
  }
  
export interface Notification {
  id: string;
  title: string;
  message: string;
  type?: NotificationType;
  action?: {
    label: string;
    onClick: () => void;
  };
  duration?: number; // Auto-dismiss duration in ms, if not provided notification stays until manually dismissed
}