import { Group } from 'api/types';
import { SparkleIcon } from 'assets/Icons';
import clsx from 'clsx';
import { calculateSaving, convertToUSD } from 'helpers/utils';
import LabelText from '../GroupNavigation/LabelText';
import PromoteButton from './PromoteButton';

type Props = {
  group: Group;
};

const SubCost = ({ cost }: { cost: number }): JSX.Element => {
  if (cost === 0) return <></>;

  return (
    <>
      <LabelText text="Off fleet" className="ml-7" variant="small" />
      <p className="text-sm font-bold">{convertToUSD(cost)}</p>
    </>
  );
};

const CostSavingsReceipt = ({group}: Props) => {
  
  const { costs, offFleetCosts, changes } = group;
  const saving = calculateSaving(costs.existing, costs.optimized);

  return (
    <div className="mb-4 mt-auto">
      <LabelText text="Savings" className="ml-4 text-xl font-bold" variant="plain" />
      <div className="rounded-lg bg-culturedBlush p-4">
        <div className="mb-4 grid grid-cols-2 items-center justify-between gap-x-4 [&>p]:text-end">
          <LabelText text="Existing" className="ml-7 text-lg" variant="plain" />
          <p className="text-xl font-bold">{convertToUSD(costs.existing)}</p>
          <SubCost cost={offFleetCosts.existing} />
          <div className='flex gap-1'><SparkleIcon className='size-6'/><LabelText text="Optimized" className="text-lg" variant="plain" /></div>
          <p className="text-xl font-bold">{convertToUSD(costs.optimized)}</p>
          <SubCost cost={offFleetCosts.optimized} />
        </div>
        <LabelText text="Total savings" />
        <div className="flex gap-4">
          <PromoteButton />
          <div className={clsx("w-full rounded-lg px-4 text-end text-white", changes === 0 ? "bg-cerebralGrey" : "bg-savingsGreen")}>
            <p className="mt-2 text-sm">{saving.percentage}</p>
            <p className="text-2xl font-bold">{saving.value}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CostSavingsReceipt;
