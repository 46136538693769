import clsx from "clsx";
import { useSelectedGroup } from "./SelectedContext";

export enum ViewMode {
  Aircraft= 'Aircraft', 
  Crews= 'Crews', 
  Combined= 'Combined',
};

const ViewTabs = () => {
  const { viewMode, setViewMode } = useSelectedGroup();
    return (
        <div className="flex gap-2 rounded-t-lg bg-culturedBlush px-4 pt-4">
        {Object.values(ViewMode).map((value) => (
          <button
            key={value}
            onClick={() => setViewMode(value)}
            className={clsx(
              'rounded-full border border-veniceBlue/50 px-2 py-1 text-sm text-veniceBlue',
              viewMode === value ? 'bg-black text-white' : "hover:bg-cerebralGrey/50"
            )}
          >
            {value}
          </button>
        ))}
      </div>
    )
}

export default ViewTabs