import { io, Socket } from 'socket.io-client';
import { FlightDetails, SocketEvents } from '../components/ChatView/types';

const API_URL = process.env.REACT_APP_API_URL;


class SocketClient {
  private socket: Socket;
  private isConnected = false;
  private connectionPromise: Promise<boolean> | null = null;

  constructor() {
    this.socket = io(`${API_URL}/chat-new-request`, {
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 3000,
      reconnectionDelayMax: 60000,
      timeout: 20000,
      transports: ['polling', 'websocket']
    });

    this.socket.on('connect', () => {
      this.isConnected = true;
      console.log('Connected to chat namespace:', this.socket.id);
    });

    this.socket.on('disconnect', () => {
      this.isConnected = false;
      console.log('Disconnected from chat namespace');
    });

    this.socket.on('connect_error', (error) => {
      console.error('Connection error:', error);
      this.isConnected = false;
    });

    // Initial connection
    this.connectionPromise = this.ensureConnection();
  }

  async ensureConnection(): Promise<boolean> {
    if (this.socket.connected) {
      this.isConnected = true;
      return true;
    }

    return new Promise((resolve) => {
      this.socket.once('connect', () => {
        this.isConnected = true;
        resolve(true);
      });
      this.socket.connect();
    });
  }

  async sendMessage(message: string): Promise<boolean> {
    try {
      // Wait for initial connection if it's still pending
      if (this.connectionPromise) {
        const connected = await this.connectionPromise;
        if (!connected) {
          console.error('Initial connection failed');
          return false;
        }
        this.connectionPromise = null;
      }

      // Ensure we're still connected
      if (!this.isConnected) {
        const reconnected = await this.ensureConnection();
        if (!reconnected) {
          console.error('Failed to reconnect');
          return false;
        }
      }

      this.socket.emit('message', message);
      return true;
    } catch (error) {
      console.error('Error sending message:', error);
      return false;
    }
  }

  onResponse(callback: (response: string) => void) {
    this.socket.on(SocketEvents.ChatResponse, callback);
  }

  onQuoteResponse(callback: (response: string) => void) {
    this.socket.on(SocketEvents.QuoteResponse, callback);
  }

  onError(callback: (error: Error) => void) {
    this.socket.on(SocketEvents.ChatError, callback);   
  }

  onEngineResponse(callback: (details: FlightDetails) => void) {
    this.socket.on(SocketEvents.EngineResponse, callback);
  }

  disconnect() {
    this.socket.disconnect();
  }

  isSocketConnected() {
    return this.socket.connected;
  }
}

export default SocketClient;