import type { Leg } from 'api/types';
import { useState } from 'react';
import LabelText from './LabelText';
import SelectOption from './SelectOption';

type Props = {
  groupIndex: number | string;
  legs: Leg[];
};

const DemandAircraftSelection = ({ legs, groupIndex }: Props) => {
  const tailNumbers = Array.from(new Set(legs.map((l) => l.tailNumber))).sort();
  const demandIds = Array.from(new Set(legs.map((l) => l.demandId).filter(Boolean))).sort();

  const [selectedTailNumber, setSelectedTailNumber] = useState<string | null>(null);
  const [selectedDemandId, setSelectedDemandId] = useState<string | null>(null);

  const isAllGroup = groupIndex === -1 
  const AircraftLabel = isAllGroup ? 'All aircraft' : `Aircraft in Group ${groupIndex}`;
  const DemandsLabel = isAllGroup ? 'All demands' : `Demands in Group ${groupIndex}`
  return (
    <>
      <LabelText text={AircraftLabel} className="sticky" />
      <div className="h-full max-h-48 overflow-y-scroll">
        {tailNumbers.map((tailNumber) => (
          <SelectOption
            key={tailNumber}
            text={tailNumber}
            isSelected={selectedTailNumber === tailNumber}
            onClick={() => {
              setSelectedDemandId(null);
              setSelectedTailNumber(tailNumber);
            }}
          />
        ))}
      </div>
      <LabelText text={DemandsLabel} className="sticky" />
      <div className="h-full max-h-48 overflow-y-scroll">
        {demandIds.map((demandId) => (
          <SelectOption
            key={demandId}
            text={demandId as string}
            isSelected={selectedDemandId === demandId}
            onClick={() => {
              setSelectedTailNumber(null);
              setSelectedDemandId(demandId as string);
            }}
          />
        ))}
      </div>
    </>
  );
};

export default DemandAircraftSelection;
