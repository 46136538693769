
import type { Crew } from "api/types";
import type { Task } from "dhtmlx-gantt";
import {
    calculateDuration, formatDatetimeRange
} from "helpers/utils";
import ReactDOMServer from "react-dom/server";
import { mapCrewPOSToLabel } from "../GanttChartView/helpers";
import './tooltip.css';

const CrewTooltipText = (task: Task, crews: Crew[]) => {
    const {id} = task; 
    const crew = crews.find((crew) => crew.id === (id as string).split("_")[0]);
    if(!crew) return '';

    const component = (
        <div className="custom-tooltip">
            <dl>
                <dt>ID</dt>
                <dd>{crew.id}</dd>
                <dt>Tail no.</dt>
                <dd>{crew.tailNumber}</dd>
                <dt>Name</dt>
                <dd>{crew.first} {crew.last}</dd>
                <dt>Position</dt>
                <dd>{mapCrewPOSToLabel(crew.pos, true)}</dd>
                <dt>Scenario</dt>
                <dd>{crew.scenario}</dd>
                <dt>Status</dt>
                <dd>{crew.status}</dd>
                <dt>Time</dt>
                <dd>{formatDatetimeRange(crew.startTimeGMT, crew.endTimeGMT)}</dd>
                <dt>Duration</dt>
                <dd>{calculateDuration(crew.startTimeGMT,  crew.endTimeGMT)}</dd>
            </dl>
        </div>
    );
    
    return ReactDOMServer.renderToString(component);
}

export default CrewTooltipText;
