import { useState } from 'react';

interface UseToggleSwitchProps {
  label: string;
  initialValue?: boolean;
}

const useToggleSwitch = ({ label, initialValue = false }: UseToggleSwitchProps) => {
  const [checked, setChecked] = useState(initialValue);

  const ToggleSwitch = () => (
    <div className="flex items-center space-x-2">
      <span className="font-medium text-veniceBlue">{label}</span>
      <label className="relative inline-flex cursor-pointer items-center">
        <input
          type="checkbox"
          className="peer sr-only"
          checked={checked}
          onChange={() => setChecked((prev) => !prev)}
        />
        <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-veniceBlue peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
      </label>
    </div>
  );

  return { checked, ToggleSwitch };
};

export default useToggleSwitch;