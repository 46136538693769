import type { Leg } from 'api/types';
import type { Task } from 'dhtmlx-gantt';
import { calculateDuration, convertToUSD, formatDatetimeRange } from 'helpers/utils';
import ReactDOMServer from 'react-dom/server';
import './tooltip.css';

const TooltipText = (task: Task, legs: Leg[]) => {
  const { id } = task;
  const leg = legs.find((leg) => leg.id === (id as string).split('_')[0]);
  if (!leg) return '';

  const component = (
    <div className="custom-tooltip">
      <dl>
        <dt>Tail no.</dt>
        <dd>{leg.tailNumber}</dd>
        <dt>Fleet type</dt>
        <dd>{leg.fleetType.replace('_', ' ').toUpperCase()}</dd>
        <dt>Aircraft</dt>
        <dd>{leg.aircraftType}</dd>
        {leg.demandId && (
          <>
            <dt>Demand</dt>
            <dd>{leg.demandId}</dd>
          </>
        )}

        <dt>Airport</dt>
        <dd>
          {leg.departAirport} - {leg.arriveAirport}
        </dd>
        <dt>Time</dt>
        <dd>{formatDatetimeRange(leg.departTimeGMT, leg.arriveTimeGMT)}</dd>
        <dt>Duration</dt>
        <dd>{calculateDuration(new Date(leg.departTimeGMT), new Date(leg.arriveTimeGMT))}</dd>
        <dt>Cost</dt>
        <dd>{convertToUSD(Number(leg.cost))}</dd>
        <dt>Scenario</dt>
        <dd>{leg.scenario}</dd>
      </dl>
    </div>
  );

  return ReactDOMServer.renderToString(component);
};

export default TooltipText;
