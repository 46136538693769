import clsx from 'clsx';

const SelectOption = ({
    text,
    isSelected,
    onClick
  }: {
    text: string;
    isSelected: boolean;
    onClick(): void;
  }) => {
    return (
      <button
        onClick={onClick}
        className={clsx(isSelected && 'bg-cerebralGrey/50', 'w-full text-left font-light text-veniceBlue')}
      >
        {text}
      </button>
    );
  };

  
export default SelectOption