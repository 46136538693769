import type { Task } from "dhtmlx-gantt";

export type ParentTask = Task & {
  scenario?: ScenarioType;
  rowClass?: string;
};

export enum TaskBarShape {
  round = "round",
  square = "square",
  hashed = "hashed",
  diamond = "diamond",
}

enum ScenarioType {
  Existing,
  Optimized,
}

export type CrewTask = Task & {
  status: string;
  isCrew: boolean;
  rowClass?: string;
};
