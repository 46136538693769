export enum ScenarioType {
    optimized = 'optimized',
    existing = 'existing'
}
  
export const OffFleetRow = 'off fleet'

export enum FleetType {
    onFleet = 'on_fleet',
    offFleet = 'off_fleet',
    unscheduled = 'unscheduled'
}

export enum LegStatus {
    unchanged = 'unchanged',
    optimized = 'optimized',
    unscheduled = 'unscheduled'
}

export enum CrewStatus {
    unchanged = 'unchanged',
    reassigned = 'reassigned',
    new = 'new', // Optimized scenario - new crew added by optimizer, previously called unscheduledInMain
    deleted = 'deleted' // Existing scenario only 
}

export enum CrewType{
    pilot = 1, 
    coPilot = 2,
}
  