import { CrewStatus, CrewType, FleetType, OffFleetRow, ScenarioType } from 'api/constants';
import type { BaseGroup, Crew, Leg } from 'api/types';
import clsx from 'clsx';
import { ViewMode } from 'components/GroupNavigation';

import type { Link, Task } from 'dhtmlx-gantt';
import { flattenDeep } from 'lodash';
import { crewTaskHeight, getStatusColorWithOpacity, getTaskBarShape, legTaskHeight, PILOT_LONG_LABEL, PILOT_ROW_LABEL, taksBarColor } from './constants';
import { CrewTask, ParentTask } from './types';
import { dropTimezone, isValidDate } from './utils';

export const mapLegToTask = (leg: Leg): Task => {
  const statusColorWithOpacity = getStatusColorWithOpacity(
    leg.scenario,
    leg.fleetType,
    leg.changedFlag,
    leg.demandId
  );

  if (!isValidDate(leg.departTimeGMT) || !isValidDate(leg.arriveTimeGMT)) {
    console.error(`Invalid date for leg ${leg.id}: depart=${leg.departTimeGMT}, arrive=${leg.arriveTimeGMT}`);
  }
  
  let parentTaskId = [leg.tailNumber, leg.scenario].join('_');
  if (leg.fleetType === FleetType.offFleet && leg.scenario === ScenarioType.optimized) {
    parentTaskId = [leg.tailNumber, leg.scenario, 'off-fleet'].join('_');
  }
  
  return {
    id: [leg.id, 'leg'].join('_'), // To avoid cyclical reference to parenet tasks, when IDs are both low level integers
    text: leg.demandId ? [leg.departAirport, leg.arriveAirport].join(' - ') : leg.arriveAirport,
    start_date: dropTimezone(leg.departTimeGMT),
    end_date: dropTimezone(leg.arriveTimeGMT),
    color: statusColorWithOpacity,
    shape: getTaskBarShape(leg.fleetType, leg.demandId),
    legId: leg.id,
    parent: parentTaskId,
    aircraftType: leg.aircraftType,
    departAirport: leg.departAirport, 
    arriveAirport: leg.arriveAirport,
    isPosition: !leg.demandId,
    changeFlag: leg.changedFlag,
    fleetType: leg.fleetType,
    scenario: leg.scenario,
    ...legTaskHeight
  };


};

export const mapCrewPOSToLabel = (type: CrewType, long = false): string => {
  return long ? PILOT_LONG_LABEL[type] : PILOT_ROW_LABEL[type]
}

export const mapAircraftToParentTasks = (
  tailNumber: string,
  aircraftType: string,
  needsCrew: boolean,
  optimizedToOffFleet: boolean,
  assignedScenarios: ScenarioType[],
  isOdd: boolean,
): ParentTask[] => {

  const rowClass = isOdd ? 'FF-odd-row' : 'FF-even-row';

  const mainTask = {
    id: [tailNumber, aircraftType].join('_'),
    text: '', // this is a hack to hide the parent row
    open: true,
    hide_bar: true,
    tailNumber,
    row_height: 10, //hack to minimize row height
    bar_height: 3,
    rowClass: 'bg-culturedBlush',
  };

  const subTasks = Object.values(ScenarioType).flatMap((scenario) => {
    const scenarioTask = {
      id: [tailNumber, scenario].join('_'),
      text: scenario,
      render: 'split',
      parent: mainTask.id,
      tailNumber,
      hide_bar: true,
      aircraftType,
      rowClass: clsx(rowClass, `FF-${scenario}-row`),
      ...legTaskHeight
    };

    const offFleetTasks = optimizedToOffFleet && scenario === ScenarioType.optimized ? [
      {
        id: [tailNumber, scenario, 'off-fleet'].join('_'),
        text: OffFleetRow,
        render: 'split',
        parent: mainTask.id,
        tailNumber,
        hide_bar: true,
        rowClass: 'FF-off-fleet-row',
        row_height: 28,
        bar_height: 25
      }
    ] : [];

    const crewTasks: ParentTask[] = needsCrew && assignedScenarios.includes(scenario) ?
      [CrewType.pilot, CrewType.coPilot].map((pos) => ({
        id: [tailNumber, scenario, pos].join('_'),
        text: mapCrewPOSToLabel(pos),
        render: 'split',
        parent: mainTask.id,
        tailNumber,
        hide_bar: true,
        rowClass,
        ...crewTaskHeight
      }))
      : [];

    return [scenarioTask,  ...crewTasks, ...offFleetTasks,];
  });

  return [mainTask, ...subTasks];
};

export const extractParentTasksFromAircaft = (legs: Leg[]): ParentTask[] => {
  const tailNumbers = Array.from(new Set(legs.map(leg => leg.tailNumber))).sort((a, b) => a.localeCompare(b));  
  const tasks = tailNumbers.flatMap((tailNumber, index) => {
    const givenLegs = legs.filter((l) => l.tailNumber === tailNumber) as Leg[];
    const assignedScenarios = Array.from(new Set(givenLegs.map((l) => l.scenario)));
    const hasOnFleetLegs = givenLegs.some(l => l.fleetType === FleetType.onFleet); 
    const optimizedToOffFleet = givenLegs.some(l => l.fleetType === FleetType.offFleet && l.scenario === ScenarioType.optimized);
    const needsCrew = givenLegs.length > 0 && hasOnFleetLegs
    const isOddRow = index % 2 === 1;
    return mapAircraftToParentTasks(tailNumber, givenLegs[0].aircraftType, needsCrew, 
      optimizedToOffFleet,
      assignedScenarios, isOddRow);
  });

  return flattenDeep(tasks);
};

export const extractLinksFromLegs = (legs: Leg[]): Link[] => {
  const links = legs.reduce((acc, leg, currentIndex) => {
    if (leg.linkId) {
      acc.push({
        id: currentIndex,
        source: leg.linkId + '_leg',
        target: leg.id + '_leg',
        type: '1'
      });
    }
    return acc;
  }, [] as Link[]);

  return links;
};

export const extractLinksFromCrews = (crews: Crew[]): Link[] => {
  const links = crews.reduce((acc, crew, currentIndex) => {
    if (crew.linkId) {
      acc.push({
        id: currentIndex,
        source:crew.linkId + '_crew',
        target:crew.id + '_crew',
        type: '1'
      });
    }
    return acc;
  }, [] as Link[]);

  return links;
};

const getCrewColor = (status: CrewStatus) => {
  const opacity = '80';
  switch (status) {
    case CrewStatus.unchanged:
    case CrewStatus.deleted:
      return taksBarColor.unchange+'1A';
    case CrewStatus.reassigned:
      return 'transparent'
    case CrewStatus.new:
      return taksBarColor.unschedule+opacity
  }
};

export const mapCrewToTasks = (crew: Crew): CrewTask => {
  const statusColorWithOpacity = getCrewColor(crew.status);

  return {
    id: [crew.id, 'crew'].join('_'), // To avoid cyclical reference to parenet tasks, when IDs are both low level integers
    start_date: dropTimezone(crew.startTimeGMT),
    end_date: dropTimezone(crew.endTimeGMT),
    text: crew.last.substring(0, 3).toUpperCase() + crew.first[0],
    parent: [crew.tailNumber, crew.scenario, crew.pos].join('_'),
    status: crew.status,
    pos: crew.pos,
    isCrew: true,
    color: statusColorWithOpacity,
    ...crewTaskHeight
  };
};

export const findDisplayGroup = (groupId: string, groups: BaseGroup[]): BaseGroup => {
  return groups.find((group) => group.id === groupId) ?? groups[0];
};

export const findListItemsFromIds = <T extends { id: string }>(ids: string[], items: T[]): T[] => {
  return items.filter((item) => ids.includes(item.id));
};

const isTaskTooClose = (taskTime: number, referenceTime: number, threshold: number) => {
  return Math.abs(taskTime - referenceTime) < threshold;
};

export const getAdjacentTaskText = (
  peerTasks: Task[],
  task: Task,
  referenceTime: number,
  isLeftSide: boolean,
  duration: number
): string => {
  if (task.isCrew || duration === 1) return '';

  const hourInMilliseconds = 60 * 60 * 1000 * (isLeftSide ? duration + 1 : duration);
  const hasNearbyTask = peerTasks.some(t => {
    const adjacentTime = isLeftSide
      ? (t.end_date as Date).getTime()
      : (t.start_date as Date).getTime();
    return isLeftSide
      ? (adjacentTime < referenceTime && isTaskTooClose(adjacentTime, referenceTime, hourInMilliseconds))
      : (adjacentTime > referenceTime && isTaskTooClose(adjacentTime, referenceTime, hourInMilliseconds));
  });

  if (hasNearbyTask) return '';

  return isLeftSide
    ? (task.isPosition ? task.departAirport : '')
    : task.arriveAirport;
};

export const sortFlightTasks = (tasks: Task[]): Task[] => {
  return tasks.sort((a, b) => {
    if (a.tailNumber !== b.tailNumber) {
      return a.tailNumber.localeCompare(b.tailNumber);
    }
    return new Date(a.start_date as Date).getTime() - new Date(b.start_date as Date).getTime();
  });
};

export const getLinksBasedOnViewMode = (
  viewMode: ViewMode,
  legLinks: Link[],
  crewLinks: Link[]
): Link[] => {
  if (viewMode === ViewMode.Aircraft) return legLinks;
  if (viewMode === ViewMode.Crews) return crewLinks;
  return [...legLinks, ...crewLinks];
};

export const validateTasks = (tasks: (Task | ParentTask | CrewTask)[]): void => {
  // Check for duplicate IDs
  const idSet = new Set();
  tasks.forEach((task) => {
    if (idSet.has(task.id)) {
      console.error(`Duplicate task ID found: ${task.id}`);
    }
    idSet.add(task.id);
  });

  // Create a map for quick task lookup and validate parent references
  const taskMap = new Map(tasks.map((task) => [task.id, task]));
  tasks.forEach((task) => {
    if (task.parent && !taskMap.has(task.parent)) {
      console.error(`Task ${task.id} has invalid parent: ${task.parent}`);
    }
  });
};