import { QueryClientProvider } from 'react-query';
import { AuthProvider } from 'routes/AuthContext';
import { queryClient } from './api/queryClient';
import { NotificationProvider } from './components/Notification';
import Routes from './routes';

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <NotificationProvider>
      <AuthProvider>
        <Routes />
      </AuthProvider>
      </NotificationProvider>
    </QueryClientProvider>
  );
}

export default App;
