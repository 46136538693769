import type { Group } from 'api/types';
import clsx from 'clsx';
import { calculateSaving } from 'helpers/utils';
import { KeyboardEvent, useState } from 'react';
import { mergeGroupsToOne } from './constants';
import LabelText from './LabelText';

type Props = {
  groups: Group[];
  selectedId: string; 
  setSelectedId: (id: string) => void;
};

const GroupSelection = ({ groups, selectedId, setSelectedId }: Props) => {
  
  const nonZeroGroups = groups.filter((group) => group.changes > 0);
  const allGroupSelection = mergeGroupsToOne(groups);
  const [focusIndex, setFocusIndex] = useState(0);

  const handleKeyDown = (e: KeyboardEvent<HTMLUListElement>) => {
    if (e.key === 'ArrowDown') {
      setFocusIndex((prevIndex) => (prevIndex + 1) % (nonZeroGroups.length + 1));
    } else if (e.key === 'ArrowUp') {
      setFocusIndex(
        (prevIndex) => (prevIndex - 1 + (nonZeroGroups.length + 1)) % (nonZeroGroups.length + 1)
      );
    } else if (e.key === 'Enter') {
      const selectedGroup =
        focusIndex === nonZeroGroups.length ? allGroupSelection : nonZeroGroups[focusIndex];
      setSelectedId(selectedGroup.id);
    }
  };

  const optionClassname = (selected: boolean) =>
    clsx('grid cursor-pointer grid-cols-3 px-2', selected && 'rounded-lg bg-cerebralGrey/50 font-bold');

  return (
    <div>
      <div className="grid grid-cols-3 px-2 uppercase">
        <LabelText text="Group" variant="small" />
        <LabelText text="Changes" variant="small" />
        <LabelText text="Savings" variant="small" />
      </div>
      <ul
        role="listbox"
        aria-activedescendant={selectedId}
        tabIndex={0}
        onKeyDown={handleKeyDown}
        
      >
        {nonZeroGroups.map((group, index) => (
          <li
            key={group.id}
            id={group.id}
            role="option"
            aria-selected={selectedId === group.id}
            onClick={() => setSelectedId(group.id)}
            className={optionClassname(selectedId === group.id)}
            tabIndex={-1}
          >
            <span>{String(index + 1).padStart(2, '0')}</span>
            <span>{group.changes}</span>{' '}
            <span>{calculateSaving(group.costs.existing, group.costs.optimized).value}</span>
          </li>
        ))}
        <li
          key={allGroupSelection.id}
          id={allGroupSelection.id}
          role="option"
          aria-selected={selectedId === allGroupSelection.id}
          onClick={() => setSelectedId(allGroupSelection.id)}
          className={optionClassname(selectedId === allGroupSelection.id)}
          tabIndex={-1}
        >
          <span>All</span>
          <span>{allGroupSelection.changes}</span>
          <span>
            {calculateSaving(allGroupSelection.costs.existing, allGroupSelection.costs.optimized).value}
          </span>
        </li>
      </ul>
    </div>
  );
};

export default GroupSelection;
