import {
  ChatContainer, MainContainer, Message,
  MessageInput, MessageList, TypingIndicator
} from '@chatscope/chat-ui-kit-react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { Transition } from '@headlessui/react';
import { CloseIcon } from 'assets/Icons';
import { NotificationType, useNotification } from 'components/Notification/';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import SocketClient from '../../api/socketClient';
import './chat.css';

type Props = {
  isOpen: boolean;
  onClose: () => void;
}

const ChatPanel: React.FC<Props> = ({ isOpen, onClose }) => {
  const { addNotification } = useNotification();
  const [messages, setMessages] = useState<Array<{text: string, isUser: boolean, isHtml?: boolean}>>([]);
  const [isConnecting, setIsConnecting] = useState(false);
  const clientRef = useRef<SocketClient | null>(null);

  const disconnectClient = useCallback(() => {
    if (clientRef.current) {
      clientRef.current.disconnect();
      clientRef.current = null;
    }
  }, []);

  const initializeClient = useCallback(async () => {
    if (clientRef.current?.isSocketConnected()) {
      return true;
    }

    // If no client exists or not connected, create a new one
    if (!clientRef.current) {
      const client = new SocketClient();
      clientRef.current = client;

      client.onResponse((response: string) => {
        setMessages(prev => [...prev, { text: response, isUser: false }]);
      });
      
      client.onQuoteResponse((text: string) => {
        setMessages(prev => [...prev, { text, isUser: false, isHtml: true }]);
      });

      client.onError((error: Error) => {
        addNotification({
          title: 'Chat Error',
          message: error.message,
          type: NotificationType.ERROR
        });
      });
    }
    setIsConnecting(true);
    try {
      const connected = await clientRef.current.ensureConnection();
      if (!connected) {
        addNotification({
          title: 'Chat connection Error',
          message: 'Failed to connect to chat server',
          type: NotificationType.ERROR, 
          action: {
            label: 'Retry',
            onClick: () => {
              disconnectClient();
              initializeClient();
            }
          }
        })
      }
      return connected;
    } finally {
      setIsConnecting(false);
    }
  }, [addNotification, disconnectClient]);

  useEffect(() => {
    if (isOpen) {
      initializeClient();
    } else {
      disconnectClient();
    }
  }, [isOpen, initializeClient, disconnectClient]);

  const handleSend = async (message: string) => {
    if (!message.trim()) return;
    const connected = await initializeClient();
    if (!connected || !clientRef.current) return;
    
    try {
      const sent = await clientRef.current.sendMessage(message);
      if (sent) {
        setMessages(prev => [...prev, { text: message, isUser: true }]);
      }
    } catch (err) {
      addNotification({
        title: 'Chat send message Error',
        message: 'Failed to send message - please try again',
        type: NotificationType.ERROR
      });
    }
  };



  return (
    <Transition
      show={isOpen}
      enter="transition ease-out duration-300"
      enterFrom="transform scale-x-0 origin-left"
      enterTo="transform scale-x-100 origin-left"
      leave="transition ease-in duration-200"
      leaveFrom="transform scale-x-100 origin-left"
      leaveTo="transform scale-x-0 origin-left"
    >
      <div className="mx-4 h-auto max-h-[600px] w-full max-w-full">
        <MainContainer>
          <button 
            className="absolute right-0 top-0 z-10 m-2 rounded-full bg-helles p-2 text-veniceBlue/80 opacity-80 hover:opacity-100"
          onClick={() => {
            disconnectClient();
            onClose();
          }}><CloseIcon /></button>
          <ChatContainer className="relative shadow-md ">
            <MessageList
              typingIndicator={
                isConnecting ? <TypingIndicator content="Connecting..." /> : null
              }
            >
              {messages.map((msg, idx) => (
                <Message
                  key={idx}
                  model={{
                    message: msg.text,
                    sentTime: "just now",
                    sender: msg.isUser ? "You" : "Assistant",
                    direction: msg.isUser ? "outgoing" : "incoming",
                    position: "single",
                    type: msg.isHtml ? "html" : "text",
                    
                  }}
                />
              ))}
            </MessageList>

            <MessageInput
              placeholder="Please provide flight details for a price quote."
              onSend={handleSend}
              attachButton={false}
              disabled={isConnecting}
            />

          </ChatContainer>
        </MainContainer>
      </div>
    </Transition>
  );
};

export default ChatPanel;