import { CrewStatus, ScenarioType } from 'api/constants';
import type { Crew } from 'api/types';
import clsx from 'clsx';
import type { Task } from 'dhtmlx-gantt';
import ReactDOMServer from 'react-dom/server';

const getBarStyle = (status: CrewStatus, scenario: ScenarioType) => {
  switch (status) {
    case CrewStatus.unchanged:
      return clsx('border-status-unchange bg-[#D2DEEA]/50');
    case CrewStatus.reassigned:
      return clsx(scenario === ScenarioType.optimized ? 'border-status-optimized bg-status-optimized/25' : 'border-status-changed bg-status-changed/25');
    case CrewStatus.new:
      return 'bg-status-optimized/25 border-status-optimized border-dashed';
    case CrewStatus.deleted:
      return 'border-status-unchange bg-[#D2DEEA]/50 border-dashed';
  }
};

const getLabelStyle = (status: CrewStatus, scenario: ScenarioType) => {
  switch (status) {
    case CrewStatus.unchanged:
      return ''
    case CrewStatus.reassigned:
      return clsx(scenario === ScenarioType.optimized ? 'text-hippieGreen' : 'text-cajaRed line-through');
    case CrewStatus.new:
      return 'text-hippieGreen';
    case CrewStatus.deleted:
      return 'opacity-50 line-through';
  }
};


const CrewBarText = (task: Task, crews: Crew[]) => {
  const { id } = task;
  const crew = crews.find((crew) => crew.id === (id as string).split('_')[0]);
  if (!crew) return '';

  const component = <div className={clsx('rounded-md border', getBarStyle(crew.status, crew.scenario),  getLabelStyle(crew.status, crew.scenario))}>{crew.status === CrewStatus.new ? '+ ' : ''}{task.text}</div>;
  return ReactDOMServer.renderToString(component);
};

export default CrewBarText;