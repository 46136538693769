import { RunIcon } from 'assets/Icons';
import { formatDatetimeUTC } from 'helpers/utils';
import React, { useCallback, useMemo, useState } from 'react';
import ChatPanel from '../ChatView/ChatPanel';
import Loader from '../Loader';
import DateTimePicker from './DatetimePicker';
import presets from './presets';
import useToggleSwitch from './useToggleSwitch'; // Import the combined hook and component

interface Props {
  isLoading: boolean;
  onSubmit: (data: { start: Date; duration: number; refreshDatabase: boolean }) => void;
}

const durationOptions = [
  { value: 1, label: '1 day' },
  { value: 2, label: '2 days' },
  { value: 3, label: '3 days' }
];

const calculateEndDate = (startDate: Date, durationDays: number) => {
  
  const endDate = new Date(startDate);
  endDate.setUTCDate(endDate.getUTCDate() + durationDays);
  endDate.setUTCHours(9, 0, 0, 0);  // Set to 9 AM UTC
  return formatDatetimeUTC(endDate, true);
}

const PlanningWindowForm = ({ isLoading, onSubmit }: Props) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [numberOfDays, setNumberOfDays] = useState<number>(1);
  const { checked: refreshDatabase, ToggleSwitch } = useToggleSwitch({ label: "Refresh Database", initialValue: true });
  const [isChatOpen, setIsChatOpen] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!selectedDate) return;
    onSubmit({
      start: selectedDate,
      duration: numberOfDays,
      refreshDatabase
    });
  };

  const handleDateChange = useCallback((date: Date | null) => {
    setSelectedDate(date);
  }, []);



  const endDate = useMemo(() => {
    if (!selectedDate) return null;
    return calculateEndDate(selectedDate, numberOfDays);
  }, [selectedDate, numberOfDays]);

  return (
    <>
    <div className="container flex h-[calc(100vh-54px)] flex-col bg-helles">
      <form
        onSubmit={handleSubmit}
        className="w-full max-w-md space-y-4 rounded-lg border bg-white p-4"
      >
        <h2 className="font-medium text-veniceBlue">Planning Window</h2>
        <DateTimePicker selectedDate={selectedDate} onChange={handleDateChange} />
        <div className="flex space-x-4">
          <span className="font-medium uppercase text-veniceBlue">Duration</span>
          <div className="space-x-4">
            {durationOptions.map((option) => (
              <label key={option.value} className="inline-flex items-center">
                <input
                  type="radio"
                  name="numberOfDays"
                  value={option.value}
                  checked={numberOfDays === option.value}
                  onChange={() => setNumberOfDays(option.value)}
                />
                <span className="ml-2">{option.label}</span>
              </label>
            ))}
          <div className='!ml-0 space-x-1 text-sm text-veniceBlue'>{endDate ? <span>Ends on {endDate}</span> : ''}</div>
          </div>

        </div>
        <ToggleSwitch />
        <div className="flex items-baseline justify-between gap-4 font-medium">
        <button
          type="submit"
          disabled={!selectedDate || isLoading}
          className="flex items-center justify-center gap-4 rounded bg-veniceBlue p-1 text-left text-white hover:bg-veniceBlue disabled:opacity-50"
        >
          {isLoading && <Loader />}
          <RunIcon /> <span className="ml-4 mr-8">Run<br/> AI Planner</span>
        </button>
        <button
          onClick={() => setIsChatOpen(!isChatOpen)}
          className="text-veniceBlue hover:underline"
        >
          {isChatOpen ? 'Hide chat' : 'Price one leg'}
        </button>
        </div>
      </form>
      <div className="mt-4 w-full max-w-md">
        <h3 className="mb-2 font-medium text-veniceBlue">Presets</h3>
        <div className="grid grid-cols-3 gap-2">
          {presets.map((preset, index) => (
            <button
              key={index}
              type="button"
              onClick={() => {
                setSelectedDate(preset.date());
                setNumberOfDays(preset.duration);
              }}
              className="flex flex-col items-center justify-center rounded border border-veniceBlue bg-white p-2 text-sm text-veniceBlue hover:bg-veniceBlue hover:text-white"
            >
              <span className="font-medium">{preset.name}</span>
              <span className="text-xs">{preset.description}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
      <ChatPanel isOpen={isChatOpen} onClose={() => setIsChatOpen(false)}/>
    </>
  );
};

export default PlanningWindowForm;
