import { faker } from '@faker-js/faker';
import { generateDummyData } from 'api/axios';
import CrewToggle from 'components/GroupNavigation/CrewToggle';
import { ViewMode } from 'components/GroupNavigation/ViewTabs';
import { useQuery } from 'react-query';
import GanttChart from './GanttChart';

const DummyChart = (): JSX.Element => {
  const { data, isLoading } = useQuery('dummy-data', generateDummyData, {
    onError(error) {
      console.error('Error:', error);
    }
  });
  if (isLoading) return <div>Loading...</div>;
  if (!data) return <div>No data</div>;

  const dummyGroupId = faker.string.nanoid();
  
  return (
    <div>
      <CrewToggle/>
      <GanttChart 
      duration={1}
      selectedGroupId={dummyGroupId} 
      selectedCrewGroupId={dummyGroupId}
      selectedCombineGroupId={dummyGroupId}
      viewMode={ViewMode.Aircraft}
      legs={data.legs} crews={data.crews} 
      />
    </div>
  );
};

export default DummyChart;
